import React, { useState,useEffect } from "react";
import Chart from "react-apexcharts";
import './Search.css'
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import { baseurl } from "../BaseUrl/Baseurl";
import ButtonLink from "../ButtonLink/ButtonLink";
import { Link } from "react-router-dom";
const BarChartComponent = () => {
  const userData = useSelector((state) => state.userDetails);
  const [word, setWord] = useState("abcdefghijklmnopqrst");
  const [show, setShow] = useState(false);
  const [chartdatastatus,setChartdatastatus]=useState(false);
  const [barHeight, setBarHeight] = useState('')
  const [modifiedWord, setModifiedWord] = useState(word);
  const [circle, setCircle] = useState("");
  const [division, setDivision] = useState("");
  const [subdivision, setSubdivision] = useState("");
  const [projectlist, setProjectlist] = useState([]);
  const [circlelist, setCirclelist] = useState([]);
  const [divisionlist, setDivisionlist] = useState([]);
  const [subdivisionlist, setSubdivisionlist] = useState([]);
  const [chartlist, setChartlist] = useState([]);
  const [projname, setProjname] = useState("");
  const isDivisionPresent = userData.searchParams.includes("Division");
  const isSubDivisionPresent = userData.searchParams.includes("Sub-Division");
  const isCirclePresent = userData.searchParams.includes("Circle");


  const len=userData.searchParams.length

  const dispatch = useDispatch();

  useEffect(
    () => {
      
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    [  ]
  );

  useEffect(() => {
    if(isCirclePresent)
    {
      
    axios({
      method: "GET",

      url: baseurl + "/api/project/list/circle",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const data = response.data;
        const circle = data.circle_list || [];
        setCirclelist(circle);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
    //Division
    if(isDivisionPresent)
    {
      const encodedcircle = encodeURIComponent(userData.circle);
      
    axios({
      method: "GET",

      url: baseurl + "/api/project/list/division?circle="+encodedcircle,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const data = response.data;
        const division = data.division_list || [];
        setDivisionlist(division);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }

    //Sub Division
    if(isSubDivisionPresent)
    {
      const encodeddivision = encodeURIComponent(userData.division);
    axios({
      method: "GET",

      url: baseurl + "/api/project/list/sub_division?division="+encodeddivision,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const data = response.data;
        const subdivision = data.sub_division_list || [];
        setSubdivisionlist(subdivision);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }

  }, []);


useEffect(()=>
{
  if(!chartdatastatus)
  {

  
  axios({
    method: "GET",

    url: baseurl + '/api/report/work_status_on_date_basis?client_id='+userData.clientId+'&role='+userData.role,

    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userData.token,
    },
  })
    .then((response) => {
      const data = response.data;
      const chartdata = data.work_status_on_date || [];
    setChartlist(chartdata)
   setChartdatastatus(true)
    setBarHeight(calculateBarHeight(chartdata));
    //////alert(JSON.stringify(chartData))
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }
  
})

const calculateBarHeight = (chartdata) => {

  return `${60 + 60 / (1 + 30 * Math.exp(-chartdata.length / 3))}%`;
};








  function handleCircle(e) {
    setCircle(e.target.value);
    setDivision([])
    setSubdivision([])
    const encodedcircle = encodeURIComponent(e.target.value);


    axios({
      method: "GET",

      url: baseurl + "/api/project/list/division?cicle="+encodedcircle,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const data = response.data;
        const division = data.division_list || [];
        setDivisionlist(division);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  function handleDivision(e) {
    setDivision(e.target.value);
setSubdivision([])
const encodeddivision = encodeURIComponent(e.target.value);
      axios({
        method: "GET",
  
        url: baseurl + "/api/project/list/sub_division?division="+encodeddivision,
  
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + userData.token,
        },
      })
        .then((response) => {
          const data = response.data;
          const subdivision = data.sub_division_list || [];
          setSubdivisionlist(subdivision);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

  }
  function handleSubDivision(e) {
    setSubdivision(e.target.value);
  }
  useEffect(()=>
  {
    setProjname(projname);
  },[projname])

  function getProjectDetails(pname) {
    setProjname(pname);

    setChartlist([])
    axios({
      method: "GET",
  
      url: baseurl + '/api/report/work_status_on_date_basis?project_name='+pname+'&role='+userData.role,
  
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const data = response.data;
        const chartdata = data.work_status_on_date || [];
      ////alert(JSON.stringify(chartdata))
      setChartlist(chartdata)
   
    setBarHeight(calculateBarHeight(chartdata));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // axios({
    //   method: "GET",

    //   url:
    //     baseurl +
    //     "/api/project/get/menu/list?project_id=" +
    //   //  userData.projectId+
    //     'PR-2'+
    //     "&role=" +
    //     userData.role,

    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: "Bearer " + userData.token,
    //   },
    // })
    //   .then((response) => {
    //     ////console.log(response.data);
    //     ////console.log("Sub Menus"+JSON.stringify(response.data));

    //     const listofmenus = response.data;

       
    //   })
    //   .catch((error) => {
    //     ////console.log(error);
    //     // setErrormsg(error.response.data.message)
    //   });

  }

  // const handleSubmit = () => {
  //   let updatedWord;

  //   if (word.length > 10) {
  //     updatedWord = word.substring(0, 10) + "...";
  //   }

  //   setModifiedWord(updatedWord); // Update state with the modified word
  //   setShow(true); // Set show to true
  // };

  
useEffect(()=>
  {
    
    if((userData.role==="AE"|| userData.role==="ADE" )&& projectlist.length==0)
    {
      const encodedcircle = encodeURIComponent(userData.circle);
      const encodeddivision = encodeURIComponent(userData.division);
      const encodedsubdivision = encodeURIComponent(userData.subdivision);
  
   
      // Construct the full URL
      const fullUrl = `${baseurl}/api/project/list?is_name_only_rqd=1&circle=${encodedcircle}&division=${encodeddivision}&subdivision=${encodedsubdivision}`;
      axios({
        method: "GET",
  
        url: fullUrl,
  
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + userData.token,
        },
      })
        .then((response) => {
          const dt = response.data || [];
          const list = dt.project_name_list ||[];
          //console.log(list);
          setProjectlist(list);
          
          
        })
        .catch((error) => {});
    }
    
  })

  function handleSubmit(event) {
    event.preventDefault();

    let updatedWord;


    // Encode the query parameter
    const encodedcircle = encodeURIComponent(circle);
    const encodeddivision = encodeURIComponent(division);
    const encodedsubdivision = encodeURIComponent(subdivision);


    // Construct the full URL
    const fullUrl = `${baseurl}/api/project/list?is_name_only_rqd=1&circle=${encodedcircle}&division=${encodeddivision}&subdivision=${encodedsubdivision}`;
    axios({
      method: "GET",

      url: fullUrl,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const dt = response.data || [];
        const list = dt.project_name_list;
        ////console.log(list);
        setProjectlist(list);
        
    // if (word.length > 10) {
    //   updatedWord = word.substring(0, 10) + "...";
    // }

    // setModifiedWord(updatedWord); // Update state with the modified word
    setShow(true); // Set show to true
        
      })
      .catch((error) => {});
  }
  const truncateWord = (word) => {
    if (word.length > 10) {
      return word.substring(0, 10) + "...";
    }
    return word;
  };

  const chartData = [
    {
      title: "Circle - Division 1 - Sub Division 1",
      series: [
        {
          name: "NH",
          data: [
            {
              x: "Sattur (Work 1)",
              y: [
                new Date("2024-09-01").getTime(),new Date("2024-10-01").getTime(),new Date("2024-11-01").getTime(),new Date("2024-12-01").getTime(),
                new Date("2025-01-01").getTime(),
              ],
            },
          ],
        },
        {
          name: "MDR ",
          data: [
            {
              x: "Tallakulam (Work 2)",
              y: [
                new Date("2024-10-01").getTime(),
                new Date("2025-03-01").getTime(),
              ],
            },
          ],
        },
        {
          name: "0DR ",
          data: [
            {
              x: "Irukkankudi (Work  3)",
              y: [
                new Date("2024-10-01").getTime(),
                new Date("2025-03-01").getTime(),
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Circle - Division 2 - Sub Division 2jhhhhhhhhhhhhhhhhjjhgjh",
      series: [
        {
          name: "ODR ",
          data: [
            {
              x: "Irukkankudi (Work 3)",
              y: [
                [new Date("2024-09-01").getTime(), new Date("2024-10-01").getTime()],
                [new Date("2024-10-01").getTime(), new Date("2024-11-01").getTime()],
                [new Date("2024-11-01").getTime(), new Date("2024-12-01").getTime()],
                [new Date("2024-12-01").getTime(), new Date("2025-01-01").getTime()],
                [null, new Date("2025-03-01").getTime()],  // Example missing February 2025
                [new Date("2025-03-01").getTime(), new Date("2025-04-01").getTime()]
              ],
            },
          ],
        },
        {
          name: "SH ",
          data: [
            {
              x: "Melur (Work 4)",
              y: [
                new Date("2025-01-01").getTime(),
                new Date("2025-06-01").getTime(),
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Circle - Division 2 - Sub Division 2",
      series: [
        {
          name: "ODR ",
          data: [
            {
              x: "Irukkankudi (Work 3)",
              y: [
                new Date("2024-09-01").getTime(),
                new Date("2025-08-01").getTime(),
              ],
            },
          ],
        },
        {
          name: "SH ",
          data: [
            {
              x: "Melur (Work 4)",
              y: [
                new Date("2025-01-01").getTime(),
                new Date("2025-06-01").getTime(),
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Circle - Division 2 - Sub Division 2",
      series: [
        {
          name: "ODR ",
          data: [
            {
              x: "Irukkankudi (Work 3)",
              y: [
                new Date("2024-09-01").getTime(),
                new Date("2025-08-01").getTime(),
              ],
            },
          ],
        },
        {
          name: "SH ",
          data: [
            {
              x: "Melur (Work 4)",
              y: [
                new Date("2025-01-01").getTime(),
                new Date("2025-06-01").getTime(),
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Circle - Division 2 - Sub Division 2",
      series: [
        {
          name: "ODR ",
          data: [
            {
              x: "Irukkankudi (Work 3)",
              y: [
                new Date("2024-09-01").getTime(),
                new Date("2025-08-01").getTime(),
              ],
            },
          ],
        },
        {
          name: "SH ",
          data: [
            {
              x: "Melur (Work 4)",
              y: [
                new Date("2025-01-01").getTime(),
                new Date("2025-06-01").getTime(),
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Circle - Division 2 - Sub Division 2",
      series: [
        {
          name: "ODR ",
          data: [
            {
              x: "Irukkankudi (Work 3)",
              y: [
                new Date("2024-09-01").getTime(),
                new Date("2025-08-01").getTime(),
              ],
            },
          ],
        },
        {
          name: "SH ",
          data: [
            {
              x: "Melur (Work 4)",
              y: [
                new Date("2025-01-01").getTime(),
                new Date("2025-06-01").getTime(),
              ],
            },
          ],
        },
      ],
    },
  ];

  // const chartData = [
  //   {
  //     title: 'Circle - Division 1 - Sub Division 1',
  //     series: [
  //       {
  //         name: 'NH',
  //         data: [
  //           {
  //             x: 'Sattur (Work 1)',
  //             y: [1693526400000, 1735689600000], // Direct milliseconds values
  //           },
  //         ],
  //       },
  //       {
  //         name: 'MDR',
  //         data: [
  //           {
  //             x: 'Tallakulam (Work 2)',
  //             y: [1696243200000, 1740902400000], // Direct milliseconds values
  //           },
  //         ],
  //       },
  //       {
  //         name: '0DR',
  //         data: [
  //           {
  //             x: 'Irukkankudi (Work 3)',
  //             y: [1696243200000, 1740902400000], // Direct milliseconds values
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Circle - Division 2 - Sub Division 2',
  //     series: [
  //       {
  //         name: 'ODR',
  //         data: [
  //           {
  //             x: 'Irukkankudi (Work 3)',
  //             y: [1693526400000, 1751548800000], // Direct milliseconds values
  //           },
  //         ],
  //       },
  //       {
  //         name: 'SH',
  //         data: [
  //           {
  //             x: 'Melur (Work 4)',
  //             y: [1735689600000, 1757107200000], // Direct milliseconds values
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   // Add more objects as needed
  // ];

  const commonOptions = {
    chart: {
      type: "rangeBar",
				   
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        // barHeight: barHeight,
        barHeight: barHeight,
        rangeBarGroupRows: true,
        distributed: false,
      },
    },
    xaxis: {
      type: "datetime",
      position: "top",

      labels: {
        style: {
          color: "#000",
          fontWeight: 'bold'
        },
      },
      axisTicks: {
        show: true,
        color: "black",
      },
      axisBorder: {
        show: true,
        height: 2,
      },
      scrollbar: {
        enabled: true,
        height: 10,
        barHeight: 30,
      },
    },
    yaxis: {
      labels: {
        maxWidth: 200,
        style: {
          fontSize: "12px",
          fontWeight: 'bold'
        },
        padding: {
          top: 20,
          bottom: 20,
        },
      },

      axisBorder: {
        show: true,
        height: 2,
      },

    },

    stroke: {
      width: 1,
    },
    fill: {
      type: "solid",
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      fontWeight: 'bold',
      markers: {
        width: 12,
        height: 12,
        radius: 2,
        strokeWidth: 0,
        strokeColor: "#fff",
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    grid: {
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd"],
  };

  return (
    <div className="cont-workstatus">
      <div class="containerex position-absolute" style={{ top: "15%" }}>
      <h5 className="mt-3 pb-3"><center><b>Work Status on Date Basis</b></center></h5>
      <div className="row mx-auto">
        <div className="  col-2 mb-1 px-0  ">
          <div className="position-sticky shadow-sm border p-2" style={{ top: "110px" }}>
          {isCirclePresent && 
           <div class=" mx-auto mb-4  ">
           <label className="text-dark mb-0 " style={{ fontSize: "14px" }} for="input">
            <b>Circle</b> 
           </label>
              <select
                className="form-select input    w-100"
                style={{ height: "35px" }}
                id="circle"
                name="circle"
                defaultValue={circle}
                value={circle}
                onChange={handleCircle}
              >
                <option value="" selected>
                            Select
                          </option>

                          {circlelist.length != 0 &&
                            circlelist.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
              </select>
              </div>}

          {isDivisionPresent &&     <div class=" mx-auto mb-4">
                <label className="text-dark mb-0 " style={{ fontSize: "14px" }} for="input" >
                 <b>Division</b> 
                </label>
              <select
                className="form-select input    w-100"
                style={{ height: "35px" }}
                id="division"
                name="division"
                defaultValue={division}
                value={division}
                onChange={handleDivision}
              >
               <option value="" selected>
                            Select
                          </option>

                          {divisionlist.length != 0 &&
                            divisionlist.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
              </select>
            </div>
}
{isSubDivisionPresent &&    <div class=" mx-auto mb-4 ">
                <label className="text-dark mb-0 " style={{ fontSize: "14px" }} for="input" >
                <b> Sub-Division</b> 
                </label>
              <select
                className="form-select input    w-100"
                style={{ height: "35px" }}
                name="subdivision"
                defaultValue={subdivision}
                value={subdivision}
                onChange={handleSubDivision}
              >
                <option value="" selected>
                            Select
                          </option>

                          {subdivisionlist.length != 0 &&
                            subdivisionlist.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
              </select>
            </div>
            
}
{(userData.role==="AE"|| userData.role==="ADE")  && 
<div class="coolinput mx-auto mb-4 ">
<ul className=" " style={{}}>
  {projectlist.length!=0 &&projectlist.map((item, index) => {
    return (
      <li key={index}>
        <Link
          className={projname === item ? "projectlistcolor1" : "projectlistcolor"}
          id="projectname"
          onClick={() => getProjectDetails(item)}
        >
         <p key={index} title={item}>
          {truncateWord(item)}
        </p>
        </Link>
      </li>
    );
  })}
</ul>
</div>
}




{(isCirclePresent || isDivisionPresent || isSubDivisionPresent) &&      <div className="pt-3 mb-3 mx-auto text-center ">
              <button
                className="mx-auto btn border p-2 rounded-lg"
                onClick={handleSubmit}
              >
                Search
              </button>
            </div>}

            <div className="border px-3" style={{ height: "220px" }}>
              <div className="pt-3" style={{ overflow: "hidden" }}>
                {/* {show && <p title={word}>{modifiedWord}</p>}{" "} */}
                
                {show && projectlist.map((word, index) => (
                    <Link
                    className={projname === word? "projectlistcolor1" : "projectlistcolor"}
          id="projectname"
          onClick={() => getProjectDetails(word)}
        >
           <p key={index} title={word}>
          {truncateWord(word)}
        </p>
        </Link>))}

   
              </div>
            </div>
          </div>
        </div>

        {/* Map over the chartData array to render multiple charts */}
        <div className="col-10 col-lg-10">
          {chartlist.length!=0 && chartlist.map((chart, index) => (
            <div key={index} className="   shadow-sm p-1 border mb-4">
              {/* Add a title for each chart */}
              <h4 className="text-center pt-3" style={{ fontSize: "16px" }}>
                {chart.title}
              </h4>
              <Chart
                options={commonOptions}
                series={chart.series}
                type="rangeBar"
                height={300}
              />
            </div>
          ))}
        </div>
      </div>
      </div>
    </div>
  );
};

export default BarChartComponent;
