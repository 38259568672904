import React, { useEffect, useState } from "react";
import { RxSlash } from "react-icons/rx";
import "./WorkDetails.css";
import auto from "../../assets/brickdemoicons/Auto cad.png";
import irc from "../../assets/brickdemoicons/pdficon.png";
// import cadimg from "../../assets/brickdemoicons/cadex.jpg";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import { baseurl } from "../BaseUrl/Baseurl";
import DocumentView from "../../Component/Modal/DocumentView";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateLoginStatus } from "../../store/userDetails";
import CustomToastContainer from "../../CustomToastContainer";

function Methodology() {
  const [show, setShow] = useState(false);
  const [cadShow, setCadShow] = useState(false);
  const [cadImg, setCadImg] = useState("");
  const [itemOfWorkList, setItemOfWorkList] = useState([]);
  const [descriptionList, setDescription] = useState([]);
  const [tableShow, setTableShow] = useState(false);
  const [err, setErr] = useState("");
  const [ChainageErr, setChainageErr] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [messageList, setMessageList] = useState([]);

  // const [chainage] = useState([1, 2, 3, 4, 5, 6]);
  const [filteredChainage, setFilteredChainage] = useState([]);

  const userData = useSelector((state) => state.userDetails);

  const dispatch = useDispatch();
  const [startChainageArray, setStartChainageArray] = useState("");
  const [endChainageArray, setEndChainageArray] = useState("");

  const handleCadOpen = (img) => {
    setCadImg(img);
    setCadShow(true);
  };

  const handleCadClose = () => {
    setCadShow(false);
    setCadImg("");
  };

  const handleShow = (item_of_work_list, description_list) => {
    setShow(true);
    setItemOfWorkList(item_of_work_list);
    setDescription(description_list);
  };

  const handleClose = () => {
    setShow(false);
    setItemOfWorkList([]);
    setDescription([]);
  };

  const tocloseModal = () => {
    setModalStatus(false);
  };
  function documentClick() {
    setModalStatus(true);
    setShow(false);
  }

  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });

  // //////console.log(userData.chainageArr);
  const [digiter, setDigiter] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessageList([]);

    if (name === "from") {
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
      setTypeOfWork([]);
      setStructure([]);
    } else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
    } else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
    } else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
    } else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }

      // setStructure([]);
      // setTypeOfWork([]);
      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            // setErr();
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          } else {
            setErr("");
          }
        }

        return updatedData;
      });
    }

    if (e.target.name === "from") {
      // setStructure([]);
      // setTypeOfWork([]);
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );
      //console.log(formData.from);

      setFilteredChainage(filtered);
    }

    //type of work , http://localhost:8080/brick/api/work_details/get/type_of_work?project_id=PR-2&start_chainage=2.03&end_chainage=5.7

    if (formData.from != "" && formData.from1 != "") {
      if (
        e.target.name === "to1" ||
        formData.to1 != "" ||
        (e.target.value != "" && formData.to != "")
      ) {
        if (formData.to != "" && formData.from != "" && formData.from1 != "") {
          axios
            .get(`${baseurl}/api/work_details/get/type_of_work`, {
              params: {
                project_id: userData.projectId,
                start_chainage: `${formData.from}.${formData.from1}`,
                end_chainage: `${formData.to}.${formData.to1}`,
              },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              const type_of_work_list = data.type_of_work_list;
              setTypeOfWork(type_of_work_list);
              // //////alert("type of work", type_of_work_list);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      }
    }

    //structure //http://localhost:8080/brick/api/work_details/get/strs_or_layers?project_id=PR-2&type_of_work=TYPE1
    if (e.target.name === "type" && e.target.value != "") {
      setStructure([]);
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
          //////console.log(strs_or_layers_list);
          // //////alert(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    setTableShow(false)

  };

  const validate = () => {
    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      axios
        .get(
          `${baseurl}/api/work_details/get/str_or_layer_ids?rqd_for=methodology&`,
          {
            params: {
              project_id: userData.projectId,
              strs_or_layers: formData.structure,
              type_of_work: formData.type,
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userData.token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data.str_or_layer_ids_data;
          ////console.log(data);
          setSearchData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // setMessageList((prevMessages) => [
          //   ...prevMessages,
          //   error.response.data.message,
          // ]);
        });

      setTableShow(true);
      setErr("");
    }
  };

  function handleSubmit(e) {
    setMessageList([]);
    e.preventDefault();
    validate();
  }

  return (
    <>
      <CustomToastContainer messageList={messageList} />

      <div
        className="container w-75 method-cont ml-4"
        style={{
          position: "absolute",
          top: "40%",
          left: "55%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="row sh border bg-white mx-auto  pt-3 pb-3 rounded-pill border"
          style={{ maxWidth: "85%" }}
        >
          <div className="col-12 "></div>
          <div className="col-12  ">
            <div className="container ">
              <div className="row">
                <div className="col-1">
                  <label
                    className="form-label mb-0 mt-1"
                    style={{ fontWeight: "500", fontSize: "15px" }}
                  >
                    Chainage&nbsp;
                  </label>
                </div>
                <div className=" col-lg-5  ">
                  <div className="w-100  p-0">
                    <div className="row ">
                      <div className="col-lg-6 ">
                        <div class=" input-group">
                          <label
                            className="form-label mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            From :&nbsp;
                          </label>
                          <select
                            onChange={handleChange}
                            value={formData.from}
                            name="from"
                            className="form-control border rounded-0"
                            style={{
                              // height: "30px",
                              width: "30px",
                              fontSize: "12px",
                            }}
                          >
                            <option value="" className="pl-0"></option>
                            {userData.chainageArr != undefined &&
                              userData.chainageArr.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>

                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="from1"
                            type="text"
                            value={formData.from1}
                            onChange={handleChange}
                            className="form-control border rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div class="input-group">
                          <label
                            className="form-label  mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            To :&nbsp;
                          </label>

                          <select
                            name="to"
                            onChange={handleChange}
                            value={formData.to}
                            className="border form-control rounded-0"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {formData.from1.length == 3 && filteredChainage.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0 rounded-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="to1"
                            type="text"
                            value={formData.to1}
                            onChange={handleChange}
                            className=" border form-control rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 px-0 ">
                  <div className=" mx-auto w-100 text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                      className=" border form-control ml-auto rounded-0"
                      style={{
                        fontSize: "12px",
                        width: "135px",
                      }}
                    >
                      <option value="">Type of Work...</option>
                      {formData.to1.length == 3 && typeOfWork.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 px-0 ">
                  <div className="mx-auto  text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="structure"
                      onChange={handleChange}
                      value={formData.structure}
                      className=" border form-control mx-auto rounded-0"
                      style={{
                        fontSize: "12px",
                        width: "175px",
                      }}
                    >
                      <option disabled value="">Structure/Layer</option>
                      {structure.map((item, index) => (
                        <option
                          key={index}
                          value={
                            item === "Structures And Layers" ? "All" : item
                          }
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-center">
                  <div className="col-1"></div>

                  <button
                    disabled={tableShow ? true : false}
                    onClick={digiter == "" ? handleSubmit : ""}
                    className="btn text-center text-white rounded-pill mt-0 "
                    style={{
                      backgroundColor: "#1953AA",
                      fontSize: "14px",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
        {
          <div className="text-red er">
            <center>{digiter}</center>
          </div>
        }
      </div>

      {tableShow && (
        <div
          className="w-75  "
          style={{
            position: "absolute",
            top: "73%",
            left: "57%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <div
            className="scrollable-div border-0 pt-0"
            style={{ height: "350px" }}
          >
            <table className=" table table-bordered table-md-responsive mx-auto mt-0">
              <thead
                className=""
                style={{
                  background: "linear-gradient(to right,#4889CF,#144A9A)",
                  fontSize: "12px",
                  position: "sticky",
                  top: "0",
                }}
              >
                <tr>
                  {(formData.structure === "Structures" ||
                    formData.structure === "All" ||
                    formData.structure === "Structures And Layers") && (
                    <th>Structure ID</th>
                  )}
                  {(formData.structure === "Layers" ||
                    formData.structure === "All" ||
                    formData.structure === "Structures And Layers") && (
                    <th>Layer ID</th>
                  )}
                  <th>Item Of Work</th>
                  <th>CAD Image</th>
                </tr>
              </thead>
              <tbody className="border" style={{ fontSize: "12px" }}>
                {searchData.map((item, index) => {
                  return (
                    <tr key={index}>
                      {(formData.structure === "Structures" ||
                        formData.structure === "All" ||
                        formData.structure === "Structures And Layers") && (
                        <td
                          className="text-dark p-1"
                          style={{ fontSize: "12px" }}
                        >
                          {item.str_id}
                        </td>
                      )}
                      {(formData.structure === "Layers" ||
                        formData.structure === "All" ||
                        formData.structure === "Structures And Layers") && (
                        <td
                          className="text-dark p-1 "
                          style={{ fontSize: "12px" }}
                        >
                          {item.layer_id}
                        </td>
                      )}
                      {/* <td className="text-dark">{item.item_of_work}</td> */}
                      <td className="p-1">
                        <button
                          style={{ fontSize: "12px" }}
                          className="btn-success btn p-1"
                          onClick={() =>
                            handleShow(item.item_of_work, item.description)
                          }
                        >
                          View
                        </button>
                      </td>
                      {/* May Contain One Or More Images ... Currently We Are Getting First One Only ... */}
                      <td className="p-1">
                        {item.cad_image_url.length == 0 ? (
                          "-"
                        ) : (
                          <button
                            className="btn p-1"
                            onClick={() => handleCadOpen(item.cad_image_url)}
                          >
                            <img src={auto} alt="img" width={25} />
                          </button>
                        )}
                      </td>{" "}
                    </tr>
                  );
                })}

                {cadShow && (
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={cadShow}
                    animation={true}
                    onHide={handleCadClose}
                  >
                    <Modal.Body>
                      <div className="bg-white">
                        <div className="w-100 d-inline-flex justify-content-end pr-2">
                          <button
                            className="btn p-0 bg-white"
                            onClick={handleCadClose}
                          >
                            <IoMdClose />
                          </button>
                        </div>
                        <div className="text-center">
                          {cadImg.map((imgSrc, index) => (
                            <img
                              key={index}
                              src={imgSrc}
                              alt={`cadimg-${index}`}
                              width={768}
                              height={500}
                            />
                          ))}
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                )}

                {modalStatus && (
                  <DocumentView status={modalStatus} btnClick={tocloseModal} />
                )}

                {show && (
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                  >
                    <Modal.Body className="">
                      <table className="table table-bordered bg-white">
                        <thead
                          style={{
                            fontSize: "14px",
                            background:
                              "linear-gradient( to right, #4889CF,#2B5A93 )",
                          }}
                        >
                          <tr>
                            <th className="rounded-0">Item Of Work</th>
                            <th>Description</th>
                            <th className="rounded-0 pt-0 pr-0">
                              <div className="w-100  d-inline-flex align-items-start justify-content-end">
                                <button
                                  className="btn p-0 "
                                  onClick={handleClose}
                                >
                                  <IoMdClose className="text-white fw-bold" />
                                </button>
                              </div>
                              <div>IRC Code</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemOfWorkList.map((item, index) => (
                            <tr key={index}>
                              <td>{item}</td>
                              <td className="w-50 text-justify">
                                {descriptionList[index]}
                              </td>
                              <td>
                                {/* <button className="btn" onClick={documentClick}>
                                  <img src={irc} alt="irc" />
                                </button> */}
                                -
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Modal.Body>
                  </Modal>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Methodology;
