import React from 'react';
import './TabList.css';
import '../Level/Level.css'
import { Outlet, Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import InputTextField from '../InputText/InputText';
import ButtonLink from '../ButtonLink/ButtonLink';
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import Search from '../LevelSearch/Search'
import Thirdpartycont from '../ThirdPartyContractorDetails/ThirdPartyControctor'
import Equipmentmgnt from '../EquipmentMgnt/EquipmentMgnt'
import Thirdparty from '../ThirdParty/ThirdParty'
import Level from '../Level/BuildingTimeline'
import { useLocation } from 'react-router-dom'
import QuantitySurvey from '../QuantitySurvey/QuantitySurvey'
import ViewWork from '../ViewWork/ViewWork'
import Projectprogress from '../ProjectProgressDetails/ProjectProgresssample2'
import Graph from '../ProjectProgressDetails/Projectprg'
import ResourceMgnt from '../ResourceMgmt/ResourceMgnt'
import Boq from '../Boq/Boq'
import Datewiseprogress from '../DatewiseBuildingprogress/Workprogress'
import BuildingSiteUpdates from '../BuildingSiteUpdates/BuildingSiteUpdates'
import Galleryphotos from '../BuildingGallery/Gallery'
import Payment from '../BuildingPayment/Payment'

const TabComponent = ({ children, tabnameupdate }) => {
  
 // const [selectedTab, setselectedTab] = useState('');
  const userData = useSelector((state) => state.userDetails);
  const [activeTab, setActiveTab] = useState(userData.projectMenuIndex);
  const dispatch = useDispatch();

  const handleTabClick = (index, label) => {
    setActiveTab(index);
   // setselectedTab(label);

    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: userData.Pagename,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        clientId: userData.clientId,
        dropdownOption: label,
        currentprojectId: userData.currentprojectId,
        projectType: userData.projectType,
        companyName: userData.companyName,
        projectName: userData.projectName,
        projectMenuIndex:index,
        resourceMenuKey:userData.resourceMenuKey,
        modalNotificationstatus:false,
        profileImage:userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: userData.projectMenus,
      })
    );
    tabnameupdate(label);
  };
  useEffect(() => {
 
  }, [userData.dropdownOption,userData.projectMenuIndex,activeTab]);

  return (
    <div className="cont-margin1">
    <div className="cont-margin-latest ">
      <div className="table1bdstylefortab">
        <div className="row table1st">
          <div className="col-lg-12 col-xl-12">
            <div className="tab-container pt-2">
             
              {children.map((tab, index) => (
                <div
                  key={index}
                 className={
                    userData.projectMenus.length <5 
                    ?
                    `tablowmenus ${index === userData.projectMenuIndex ? 'active1 pt-4' : 'pt-4 '}`
                     :
                    `tab ${index === userData.projectMenuIndex ? 'active1 pt-4' : 'pt-4 '}`}

                  onClick={() => handleTabClick(index, tab.props.label)}
                >
                  <center>{tab.props.label} </center>
                  {/* {tab.props.label === 'QS' && <div className="tab-spaceforRoad"></div>}
                  {tab.props.label === 'Work Reference' && <div className="tab-spaceforRoad1"></div>} */}
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-12 col-xl-12"></div>
          {children[userData.projectMenuIndex]}
        </div>
      </div>
    </div>
  </div>
  );
};

//Component Check
const renderDynamicComponent = (menu, tableData) => {

  switch (menu) {

    case 'Project Timeline':

      return <Level data={tableData} />;


    case 'QS':

      return <Boq />;


    case 'Work Reference':

      return <Search data={tableData} />;


    case 'Project Progress Details':

      return <Graph />;


    case 'Sub Contractor':

      return <Thirdparty />;


    case 'Resource':

      return <ResourceMgnt />;


    case 'Date Wise Work Progress':

      return <Datewiseprogress />;

    case 'Site Updates':

      return <BuildingSiteUpdates />;


    case 'Gallery':

      return <Galleryphotos />;

      
      case 'Payment':

      return <Payment data={tableData}/>;

    default:

      <Level data={tableData} />;
  }

};



const Tab = ({ children, tabnameupdate }) => {
  return <div className="tab-content">{children}</div>;
};
const WeareHere = () => {
  const [tableData, setTableData] = useState([]);
  const userData = useSelector((state) => state.userDetails);
  const location = useLocation();
  const pid = location.state?.id;

  const [btnname, setBtnname] = useState('');
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [project_renewalid, setProject_renewalid] = useState('');
  const [ticketno, setTicketno] = useState('');
  const [itemlist, setItemlist] = useState([]);
  const [fulllist, setfulllist] = useState([]);
  const [successstatus, setSuccessstatus] = useState(false);
  const [error, setError] = useState('');
  const [selectedOption, setSelectedOption] = useState(userData.dropdownOption);
  const dispatch = useDispatch();

  const tabnameupdate = (label) => {
    setSelectedOption(label);
  };

  useEffect(() => {
  
      axios({
        method: 'get',

        url: baseurl + '/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        }
      })
      .then((response) => {
        //console.log(response.data);
        const dat = response.data;
        setTableData(dat);
      })
      .catch((error) => {
        //console.log(error);
      });
      //console.log('Redux State:', userData);

      
  }, [userData.currentprojectId, userData.dropdownOption,userData.projectMenuIndex]);

  return (
    <div className="tab-content">
       <TabComponent tabnameupdate={tabnameupdate}>
      {/*  <Tab label="Project Timeline">

          {userData.dropdownOption === 'Project Timeline' && tableData.length !== 0 && <Level data={tableData} />}
        </Tab>
        <Tab label="QS">{userData.dropdownOption === 'QS' && <Boq />} </Tab>
        <Tab label="Work Reference">{userData.dropdownOption === 'Work Reference' && tableData.length !== 0 &&<Search data={tableData} />}</Tab>
        <Tab label="Project Progress details">
          {userData.dropdownOption === 'Project Progress details' && tableData.length !== 0 && <Graph />}
        </Tab>
        <Tab label="Sub Contractor Management">{userData.dropdownOption === 'Sub Contractor Management' && <Thirdparty />}</Tab>
        <Tab label="Resource Management">{userData.dropdownOption === 'Resource Management' &&<ResourceMgnt />}</Tab>
        <Tab label="DateWise Work Progress">{userData.dropdownOption === 'DateWise Work Progress' && <Datewiseprogress />}</Tab>
        <Tab label="Site Updates">{userData.dropdownOption === 'Site Updates' && <BuildingSiteUpdates />}</Tab> */}
     
     {userData.projectMenus.length != 0 && userData.projectMenus.map((menu, index) => (

<Tab label={menu} key={index}>

  {userData.dropdownOption === menu && tableData.length !== 0 &&
    renderDynamicComponent(menu, tableData)
  }
</Tab>


))}
      </TabComponent>
    </div>
  );
};

export default WeareHere;