import React from 'react';
import RadialBar from '../ApexCharts/RadialBar1';
import './RoadProjectDashboard.css';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import StatusupdateModal from '../Modal/Edit'
import Renewal from '../Modal/Renewal'
import MaterialRquest from '../Modal/MaterialRequest'
import ViewTickets from '../Modal/ViewTickets'
import { baseurl } from '../BaseUrl/Baseurl'
import completed from '../../assets/images/completed-5.png'
import inprogress from '../../assets/images/buttons/inprogress.png'
import received from '../../assets/images/buttons/received.png'
import delayed from '../../assets/images/buttons/delayed.png'
import cancelled from '../../assets/images/buttons/cancelled.png'
import sandcorrect from '../../assets/images/buttons/sandcorrect.png'
import brickcorrect from '../../assets/images/buttons/brickcorrect.png'
import stonescorrect from '../../assets/images/buttons/stonescorrect.png'
import ironrodcorrect from '../../assets/images/buttons/ironrodcorrect.png'
import cementcorrect from '../../assets/images/buttons/cementcorrect.png'
import ordered from '../../assets/images/buttons/ordered.png'
import sandhead from '../../assets/images/resources/sandhead.png'
import brick from '../../assets/images/resources/brick.png'

import cement from '../../assets/images/resources/cement.png'
import ironrod from '../../assets/images/resources/ironrod.png'
import sand from '../../assets/images/resources/sand.png'
import stones from '../../assets/images/resources/stones.png'
import tiles from '../../assets/images/resources/tiles.png'
import LineChart from '../ApexCharts/LineChart'
import LineChart1 from '../ApexCharts/LineChart1'
import MultiBarGraph from '../ApexCharts/Multibarchart'
import LineChart2 from '../ApexCharts/LineChart2';
import cancelledimg from '../../assets/images/Status/cancelled.png'
import renewal from '../../assets/images/Status/renewal.png'
import renewed from '../../assets/images/Status/renewed.png'
import suspended from '../../assets/images/Status/suspended.png'
import $ from 'jquery';
import axios from 'axios'
import DocumentView from '../Modal/DocumentView'
import { connect, useDispatch, useSelector } from 'react-redux'
import img from "../../assets/brickdemoicons/pdficon.png";
import Remark from "../../assets/brickdemoicons/Remarks (2).png";
import Tags from '../../assets/brickdemoicons/tag copy.png';
import Description from '../../assets/brickdemoicons/description.png';
import Mla from '../../assets/brickdemoicons/mla.png';
import Actualcomp from '../../assets/brickdemoicons/Actual completion.png';
import Actualstart from '../../assets/brickdemoicons/Actual Start.png';
import GO from '../../assets/brickdemoicons/GO.png';
import date from '../../assets/brickdemoicons/Date.png';
import Gono from '../../assets/brickdemoicons/GO No..png';
import mp from '../../assets/brickdemoicons/Mp.png';
import scheme from '../../assets/brickdemoicons/Scheme.png';
import Lumpsum from '../../assets/brickdemoicons/Lump Sum Amount.png';
import sanction from '../../assets/brickdemoicons/Sanction.png';
import attachicon from '../../assets/brickdemoicons/attached-file.png';
import Workname from '../../assets/brickdemoicons/Work Name.png';
import contractvalue from '../../assets/brickdemoicons/contract value.png';

import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import iconimg from '../../assets/images1/Projectstatus.png'
import banner from '../../assets/images1/backgroundabstractwhite.png'
import search from '../../assets/images1/Vector.png'
import icon1 from '../../assets/images1/Vector1.png'
import icon2 from '../../assets/images1/Stack.png'
import icon3 from '../../assets/images1/CalendarCheck.png'
import icon4 from '../../assets/images1/ShirtFolded.png'
import icon5 from '../../assets/images1/Money.png'
import icon6 from '../../assets/images1/SoccerBall.png'
import icon7 from '../../assets/images1/TidalLogo.png'
import icon8 from '../../assets/images1/Vector2.png'
import icon9 from '../../assets/images1/ReplitLogo.png'
import icon10 from '../../assets/images1/View.png'
import icon11 from '../../assets/images1/btnicon.png'
import icon12 from '../../assets/images1/map.png'
import icon13 from '../../assets/images1/Vector2.png'
import icon14 from '../../assets/images1/dots.png'
import icon15 from '../../assets/images1/iconx.png'
import icon16 from '../../assets/images1/CalendarX.png'
import icon17 from '../../assets/images1/pngwing.png'


const WeareHere = () => {
  const [selectedOption, setSelectedOption] = useState('')
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [project_renewalid, setProject_renewalid] = useState('');
  const [radialData, setRadialData] = useState('');
  const [ticketno, setTicketno] = useState('');
  const [list, setList] = useState([]);
  const [list1, setList1] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [zaxis, setZaxis] = useState([]);
  const [itemOfWorkover, setItemOfWorkover] = useState([]);
  const [itemCompletedover, setItemCompletedover] = useState([]);
  const [itemPendingover, setItemPendingover] = useState([]);
  const userData = useSelector((state) => state.userDetails)
  const [levellist, setLevelist] = useState('');
  const [data2, setData2] = useState([]);
  const drop = React.useRef(null);
  const [materialRequestId, setMaterialRequestId] = useState('');
  const [materialRequestprojectid, setMaterialRequestprojectid] = useState('');
  const [materialRequeststatus, setMaterialRequeststatus] = useState(false)
  const [materialRequestedstatus, setMaterialRequestedstatus] = useState(false)
  ///////////////drop down list ///////////////////////////////////////
  const [imagelist, setImagelist] = useState('');
  const [dataStatus, setDataStatus] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [purchaseorder, setPurchaseorder] = useState('');
  const [purchaseorderurldownload, setPurchaseorderurldownload] = useState('');
  const [successstatus, setSuccessstatus] = useState(false);
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [successstatus2, setSuccessstatus2] = useState(false);
  const [circlelist, setCirclelist] = useState([]);
  const [divisionlist,setDivisionlist] = useState([]);
  const [subdivisionlist,setSubdivisionlist] = useState([]);
  const [arealist, setArealist] = useState([]);
  const [circle, setCircle] = useState('');
  const [division,setDivision] = useState('');
  const [subdivision,setSubdivision] = useState('');
  const [area, setArea] = useState('');
  const [array, setArray] = useState([]);
  const [attach,setAttach]=useState('')
  const [attachstatus,setAttachstatus]=useState(false)
  const dispatch = useDispatch()

  function getAttachment(projectid)
  {
    if(!attachstatus)
      {
  
    axios
    
    .get(baseurl + '/api/work_master/get/attachment?project_id='+projectid, {
     // responseType: 'arraybuffer', // Ensure response is in binary format
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },

    })

    .then((response) => {
   ////console.log(response.data)

   const data=response.data
   
const byteCharacters = atob(data); // Base64 decode if required
const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
const byteArray = new Uint8Array(byteNumbers);
const blob = new Blob([byteArray], { type: 'application/pdf' });
const url = URL.createObjectURL(blob);

setAttach(url)
setAttachstatus(true)
    
    })
    .catch((error) => {
      //console.log(error)
      ////console.log(error.response.data.message)
      // setErrormsg(error.response.data.message)
    
    })
      }
    }

  useEffect(() => {
  

    
    // setItemCompletedover([])
    // setItemOfWorkover([])
    // setItemPendingover([])
    // setRadialData(0)
    // setXaxis([])
    // setYaxis([])
    // setZaxis([])



    // axios({
    //   method: 'get',

    //   url: baseurl + '/api/plan/specifications/get/item_of_work_progress?project_name=' + levellist,

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: 'Bearer ' + userData.token,
    //   }
    // })

    //   .then((response) => {

    //     ////console.log(response.data)
    //     const lldat1 = response.data
    //     ////console.log("level based item graphssss", lldat1)
    //     const itemwork1 = lldat1.item_of_works
    //     setItemOfWorkover(itemwork1)
    //     const itemcomp1 = lldat1.no_of_structures_completed
    //     setItemCompletedover(itemcomp1)
    //     const itempend1 = lldat1.no_of_structures_pending
    //     setItemPendingover(itempend1)


    //   })
    //   .catch((error) => {
    //     ////console.log("levelerr1", error)

    //   })


    ///////////////////////////////////////////////////////////////////////
    //   axios({
    //     method: 'GET',

    //     url: baseurl+'/api/plan/specifications/get/project_progress?project_name='+levellist,

    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       Authorization: 'Bearer ' + userData.token,
    //     }
    //   })

    //      .then((response) => {

    //        ////console.log(response.data)
    //        const RequestData = response.data
    //        // setRequestdata1(RequestData)
    //        ////console.log("request123", RequestData)
    // //  ////alert("Running"+levellist)
    //        const xaxis1 = RequestData.actual_completion_date_level_data

    //        const xax = xaxis1.completed_level



    //        setXaxis(xax)

    //        const yaxis1 = RequestData.due_date_level_data
    //        const yax = yaxis1.planned_level



    //        setYaxis(yax)


    //        const zaxis1 = RequestData.due_date_level_data
    //        const zax = zaxis1.planned_date



    //        setZaxis(zax)
    //        ////console.log(zax)

    //        /////////////////////// radial bar ///////////////////////////////////


    //        axios({
    //         method: 'GET',

    //         url: baseurl+'/api/project/get/progress_percentage?project_name='+levellist,

    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           Authorization: 'Bearer ' + userData.token,
    //         }

    //       })
    //         .then((response) => {
    //           ////console.log(response.data)
    //           const radialData1 = response.data
    //           ////console.log("radialbar",radialData1)

    //           setRadialData(0)
    //           setRadialData(radialData1.percentage_completed)
    //         })
    //         .catch((error) => {
    //           ////console.log(error)
    //           // setErrormsg(error.response.data.message)
    //         })



    //      })


    //Renwal list
    // axios({
    //   method: 'GET',

    //   url: baseurl + '/api/project/renewal/list',

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: 'Bearer ' + userData.token,
    //   }

    // })
    //   .then((response) => {
    //     ////console.log(response.data)
    //     const renewalData = response.data
    //     setRenewallist(renewalData)
    //     setSuccessstatus2(true)
    //   })


    //   .catch((error) => {
    //     ////console.log(error)
    //     // setErrormsg(error.response.data.message)
    //   })

    // axios({
    //   method: 'GET',

    //   url: baseurl + '/api/project/list/get?client_id=' + userData.clientId,

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: 'Bearer ' + userData.token,
    //   }
    // })

    //   .then((response) => {

    //     ////console.log(response.data)
    //     const RequestData = response.data
    //     // setRequestdata1(RequestData)
    //     ////console.log("request list ", RequestData)
    //     // ////alert(RequestData)

    //     setList1(RequestData)
    //     setList1(RequestData)
    //     if (levellist == '') {
    //       RequestData.map((item, index) => {
    //         if (index == 0) {
    //           setLevelist(item.project_name)
    //         }
    //       })
    //     }
    //   })
    //   .catch((error) => {

    //     ////console.log(error)
    //     // setErrormsg(error.response.data.message)
    //   })
if(!dataStatus)
{
  

    axios({
      method: 'GET',

      url: baseurl + `/api/project/list?user_id=`+userData.userId
      // + userData.projectName,
,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token,
      }
    })

      .then((response) => {
      
        ////console.log(response.data)
        const RequestData = response.data
        // setRequestdata1(RequestData)
        ////console.log("requesttttt", RequestData)
        const start = RequestData[0].start_chainage.split("/")[0];
        const end = RequestData[0].end_chainage.split("/")[0];
        const projectid = RequestData[0].project_id;
 
        const generatedArray = generateArray(parseInt(start), parseInt(end));
        setArray(generatedArray);
      
        onReduxupdate(generatedArray,projectid);
        setList(RequestData)
       getAttachment(projectid)
        setSuccessstatus1(true)
      })
      .catch((error) => {

        ////console.log(error)
        // setErrormsg(error.response.data.message)
      })
      setDataStatus(true)
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //Ticket list
    // axios({
    //   method: 'GET',

    //   url: baseurl + '/api/support/issue/ticket/list',

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: 'Bearer ' + userData.token,
    //   }

    // })
    //   .then((response) => {
    //     ////console.log(response.data)
    //     const TicketData = response.data
    //     setTicketlist(TicketData)
    //   })
    //   .catch((error) => {
    //     ////console.log(error)
    //     // setErrormsg(error.response.data.message)
    //   })
    //mATERIAL RQUEST


    // axios({
    //   method: 'GET',

    //   url: baseurl + '/api/request/list/resources_requested_list?client_id=' + userData.clientId,

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: 'Bearer ' + userData.token,
    //   }

    // })
    //   .then((response) => {
    //     const data = response.data;
    //     const mt = data.resources_requested || [];

    //     ////console.log("&&&&&&&&" + mt)
    //     setData2(mt)
    //     setSuccessstatus(true)
    //     //////alert(data2)
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching data:', error);
    //   });

  }, [
  ])






// useEffect(()=>
// {

//   axios({
//       method: 'GET',

//       url: baseurl + '/api/project/list/circle',

//       headers: {
//         'Content-Type': 'multipart/form-data',
//         Authorization: 'Bearer ' + userData.token,
//       }

//     })
//       .then((response) => {
//         const data = response.data;
//         const circle = data.circle_list || [];
//         setCirclelist(circle)
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//       });
//      //Division
//       axios({
//         method: 'GET',
  
//         url: baseurl + '/api/project/list/division',
  
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: 'Bearer ' + userData.token,
//         }
  
//       })
//         .then((response) => {
//           const data = response.data;
//           const division = data.division_list || [];
//           setDivisionlist(division)
//         })
//         .catch((error) => {
//           console.error('Error fetching data:', error);
//         });

//   //Sub Division
//   axios({
//     method: 'GET',

//     url: baseurl + '/api/project/list/sub_division',

//     headers: {
//       'Content-Type': 'multipart/form-data',
//       Authorization: 'Bearer ' + userData.token,
//     }

//   })
//     .then((response) => {
//       const data = response.data;
//       const subdivision = data.sub_division_list || [];
//       setSubdivisionlist(subdivision)
//     })
//     .catch((error) => {
//       console.error('Error fetching data:', error);
//     });


//  //Sub Division
//  axios({
//   method: 'GET',

//   url: baseurl + '/api/project/list/area',

//   headers: {
//     'Content-Type': 'multipart/form-data',
//     Authorization: 'Bearer ' + userData.token,
//   }

// })
//   .then((response) => {
//     const data = response.data;
//     const area = data.area_list || [];
//     setArealist(area)
//   })
//   .catch((error) => {
//     console.error('Error fetching data:', error);
//   });


// },[])


function handleCircle(e) {

  setCircle(e.target.value)

}
function handleDivision(e) {

  setDivision(e.target.value)

}
function handleSubDivision(e) {

  setSubdivision(e.target.value)

}
function handleArea(e) {

  setArea(e.target.value)

}


  // const requestEdit = (projectid, requestid, status) => {

  //   setMaterialRequestId(requestid)
  //   setMaterialRequestprojectid(projectid)
  //   setMaterialRequestedstatus(status)
  //   setMaterialRequeststatus(true);

  // };
  // const toggleModelStatus = () => {

  //   setRequeststatus(false)
  // };
  // const toggleModelStatus1 = () => {

  //   setUpdatestatus(false)

  // };
  // const toggleRenewalStatus = () => {
  //   ////console.log("***********")
  //   setRenewalstatus(false)
  // };
  // const toggleViewTicketStatus = () => {
  //   ////console.log("***********")
  //   setViewticketstatus(false)
  // };
  // const requestdata = () => {
  //   ////console.log("*****" + requeststatus)
  //   setRequeststatus(true)
  // }
  // const Editdata = (id) => {
  //   setProjectid(id)
  //   setUpdatestatus(true)
  // }
  // const RenewalUpdate = (id) => {
  //   setProject_renewalid(id)
  //   setRenewalstatus(true)
  // }
  // const ViewticketstatusUpdate = (id) => {
  //   setTicketno(id)
  //   setViewticketstatus(true)
  // }

  // const closeMaterialRequestModal = () => {
  //   setMaterialRequeststatus(false);

  //   //To refresh the table


  //   axios({
  //     method: 'GET',

  //     url: baseurl + '/api/request/list/resources_requested_list?client_id=' + userData.clientId,

  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //       Authorization: 'Bearer ' + userData.token,
  //     }

  //   })
  //     .then((response) => {
  //       const data = response.data;
  //       const mt = data.resources_requested || [];
  //       ////console.log("&&&&&&&&" + mt)
  //       setData2(mt)

  //       //////alert(data2)
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // };

  // const tocloseModal = () => {
  //   setModalStatus(false);
  // };
  // function documentClick(reqid, purchaseorderurl) {

  //   setPurchaseorderurldownload(purchaseorderurl)

  //   axios({
  //     method: 'GET',

  //     url: baseurl + '/api/purchase_order/view_report?request_id=' + reqid,

  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //       Authorization: 'Bearer ' + userData.token,
  //     }

  //   })
  //     .then((response) => {

  //       const res = response.data

  //       const doc = res.purchase_order

  //       setPurchaseorder(doc)
  //     })
  //     .catch((error) => {
  //       ////console.log(error)
  //       // setErrormsg(error.response.data.message)
  //     })
  //   setModalStatus(true)
  // };
  // const samplelist = [{ "level": "F0'", "status": "Completed", "cdate": "20-01-2024", "pdate": "30-01-2024", "Pstatus": "Paid", "pamount": "200000" },
  // { "level": "F0", "status": "Completed", "cdate": "19-02-2024", "pdate": "28-02-2024", "Pstatus": "Not Paid", "pamount": "NA" },]

  // const userData = useSelector((state) => state.userDetails);
  const [workmasterdetails,setWorkmasterdetails]=useState([])

  // const [modalStatus, setModalStatus] = useState(false);
  const tocloseModal = () => {
    setModalStatus(false);
  };
  function documentClick() {
    setModalStatus(true);
  }


  

  const generateArray = (start, end) => {
    const result = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  };

  function onReduxupdate(arr,projectid) {

    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: userData.Pagename,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: projectid,
        companyName: userData.companyName,
        clientId: userData.clientId,
        // currentprojectId:pid,
        // dropdownOption:'Project Timeline',
        // projectType:ptype,
        projectName: userData.projectName,
        projectMenuIndex: userData.projectMenuIndex,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: userData.projectMenus,
        sidebarReportsMenus: userData.sidebarReportsMenus,
        sidebarWorkDetailsMenus: userData.sidebarWorkDetailsMenus,
        dropdownViewStatus: userData.dropdownViewStatus,
        chainageArr:arr,
        projectStatus:true,
        projectsubname:'Dashboard',
        loginUsername:userData.loginUsername,
        engineerLoginStatus:false,
        searchParams:userData.searchParams,
        statename:userData.statename,
        circle:userData.circle,
        division:userData.division,
        subdivision:userData.subdivision
      })
    );
  }


  return (

    // <div className={userData.sidebartoggleStatus ? 'cont-margin-sidebar ' : 'cont-margin1'} style={{ backgroundImage: `url(${banner})` }}>
    <div className='cont-margin1 '
    // style={{ backgroundImage: `url(${banner})` }}
    >
      <div className="container margindivnew  mb-0 ">

      <div class="row">
        <div class="col-12 col-lg-12 col-xl-12 projname pb-3">
        <center>{userData.projectName}</center>
        </div>
 
  </div>
      

            {list.length != 0 && list.map((item, index) => {
              const names = item.site_engineer_names || []
              const namelen = names.length
              return (
                <div className='projectdetailsdiv '>
              
                  <div className='paddivallside bg-white ' style={{height:'200px'}}>


                    <div class="row ">
                      {/* <div class="col-xl-12 col-lg-12 text-right "><img src={icon13} width={13} height={10} />
                        &nbsp;&nbsp;&nbsp;&nbsp;

                        <img src={icon14} /></div> */}
                      <div class="col-xl-9 col-lg-9 dotted-right">
                        <div class='row sizeofprojectlist'>
                          
                        {/* <div class="col-xl-9 col-lg-9 "></div>
                          <div class="col-xl-3 col-lg-3 ">
                        <button className="viewproject">&nbsp;<img src={icon10} width={15} height={12} />&nbsp;View&nbsp;Project</button>
                       </div> */}
                          <table class="custom-table-project g-0">
                            <tbody>
                         
                              {/* <tr>
                                <td className='text-left'>
                                  <img src={icon1} width={13} height={13} />&nbsp;&nbsp;Project Name
                                </td>
                                <td className='text-left'>
                                  <font className='projectnamecolor'>: &nbsp; {item.project_name}</font>
                                </td>
                              </tr> */}
                              <tr>
                                <td className='text-left'>
                                  <img src={icon2} width={13} height={13} />&nbsp;&nbsp;Project Type
                                </td>
                                <td className='text-left blueprojectlist'>
                                  : &nbsp; {item.project_type}
                                </td>
                                <td className='text-left'>
                                  <img src={icon4} width={13} height={13} />&nbsp;&nbsp;Scheme of Works
                                </td>
                                <td className='text-left'>
                                  : &nbsp; <button class="viewbtncolor1">View</button>
                                </td>
                              </tr>
                              <tr>
                                
                                <td className='text-left'>
                                  <img src={icon6} width={13} height={13} />&nbsp;&nbsp;Circle
                                </td>
                                <td className='text-left blueprojectlist'>
                                  : &nbsp; {item.circle}
                                </td>
                                <td className='text-left'>
                                <img src={icon7} width={13} height={13} />&nbsp;&nbsp;Kilometer
                                </td>
                                <td className='text-left blueprojectlist'>
                                : &nbsp; <font className='startend'>Start</font> &nbsp; <font className='bluebackview'>{item.start_chainage}</font>
                                  &nbsp;&nbsp;<font className='startend'>End</font> &nbsp; <font className='bluebackview'>{item.end_chainage}</font> &nbsp;&nbsp;
                                  <font className='startend'>Total Length</font>&nbsp; <font className='bluebackview'>{item.total_chainage_length}</font>
                                </td>
                              </tr>
                            
                              <tr>
                                <td className='text-left'>
                                  <img src={icon3} width={13} height={13} />&nbsp;&nbsp;Division
                                </td>
                                <td className='text-left blueprojectlist'>
                                  : &nbsp; {item.division}
                                </td>
                                <td className='text-left'>
                                <img src={icon6} width={13} height={13} />&nbsp;&nbsp;Budget
                                </td>
                                <td className='text-left'>
                                : &nbsp; {item.budget}
                                </td>
                              </tr>

                              <tr>
                                <td className='text-left'>
                                  <img src={icon16} width={13} height={13} />&nbsp;&nbsp;Sub Division
                                </td>
                                <td className='text-left blueprojectlist' >
                                  : &nbsp; {item.sub_division}
                                </td>
                                <td className='text-left'>
                                  
                                </td>
                                <td className='text-left blueprojectlist'>
                                  
                                </td>
                              </tr>
                            
                            </tbody>
                          </table>

                       

       



                        </div>

                        {/* <div class="col-xl-12 col-lg-12 pt-3">

                          <center>
                          
                          
                            <button className="lastupdated">&nbsp;<img src={icon11} width={10} height={12} />&nbsp;View&nbsp;History</button>
                          </center>
                         

                        </div> */}

                      </div>
                      
                      <div className="  col-xl-3 col-lg-3 ">

                        <div class="pt-3"> <center><b>Progress:</b></center></div>
                        {item.progress != undefined && item.progress != null && item.progress != '' &&
                          <RadialBar 
                            SeriesValue={item.progress}
                            radialwidth={220}
                            labelValue={item.progress}
                            radialcolor={((parseInt(item.progress)) < 50) ? '#00A350' : (parseInt(item.progress) >= 50 && parseInt(item.progress) <= 80) ? '#009933' : '#00e600'}
                          ></RadialBar>}
                        {/* <center><img src={icon12} width={15} height={15} />&nbsp;<b>Google Map</b>:&nbsp; <button class="viewbtncolor">View</button></center> */}

                        
                      </div>
                      

     


                    </div>

                  </div>

                </div>)
            })}


          </div>
           <div className='container pt-0'>
          <div className=' sh mx-auto row   '>
         
                      
                  <table  className=' table   table-borderless  table-striped col-7 ' id="wm1">
                  {list.map((item,index)=>{
                        return(


<tbody key={index} >

{modalStatus && attach!='' &&(
                        <DocumentView
                          status={modalStatus}
                          btnClick={tocloseModal}
                          imageUrl={attach}
                          imageData={item.attachment_url
                            .replace(/width\s*=\s*['"]600['"]/g, 'width="400"')
                            .replace(
                              /height\s*=\s*['"]auto['"]/g,
                              'height="510"'
                            )}
                        />
                      )}
          <tr>
        
            <td className=' t w' ><img src={scheme} width={24} />&nbsp;Scheme</td>
             <td className='t1 w1 ' >:</td>
            <td className='t2 blueprojectlist'>{item.scheme}</td> 
          </tr>
          <tr>
        
            <td className=' t w' ><img src={Remark} width={24} />&nbsp;Type of Work</td>
             <td className='t1 w1' >:</td>
             <td className='t2 blueprojectlist'>{item.type_of_work}</td>   
          </tr>
          <tr>
          
            <td className=' t w' ><img src={Workname} width={24} />&nbsp;Work Code</td>
             <td className='t1 w1' >:</td>
            <td className='t2 blueprojectlist'>{item.work_code}</td>
          </tr>
          <tr>
          
            <td className=' t w' ><img src={Workname} width={24} />&nbsp;Work Name</td>
             <td className='t1 w1' >:</td>
            <td className='t2 blueprojectlist'>	{item.work_name}</td>
          </tr>
          <tr>
          
            <td className=' t w' ><img src={sanction} width={24} />&nbsp;Sanctioned Amount</td>
             <td className='t1 w1' >:</td>
            <td className='t2 blueprojectlist'>{item.sanctioned_amnt}</td>
          </tr>
          <tr>
          
            <td className=' t w' ><img src={Lumpsum} width={24} />&nbsp;Lump Sum Amount</td>
             <td className='t1 w1' >:</td>
            <td className='t2 blueprojectlist'>{item.other_lumpsum_amnt}</td>
          </tr>
          <tr>
          
            <td className=' t w' ><img src={contractvalue} width={24} />&nbsp;Contract Value</td>
            <td className='t1 w1' >:</td>
            <td className='t2 blueprojectlist'>{item.contract_value}</td>
          </tr>
          <tr>
          
            <td className=' t w' ><img src={attachicon} width={24} />&nbsp;Attachment</td>
             <td className='t1 w1' >:</td>
            <td className='t2 '> <button
                          onClick={documentClick}
                          className="btn btn-success btn-sm d-inline-flex align-items-center"
                        >
                          <img src={img}  alt="img" />
                          &nbsp;&nbsp; View
                        </button></td>
            {/* //attachment_url */}
          </tr>
          <tr>
          
            <td className=' t w' ><img src={Description} width={24} />Description</td>
             <td className='t1 w1' >:</td>
            <td className='t2 blueprojectlist'>{item.description}</td>
          </tr>
          <tr>
            
            <td className=' t w' ><img src={Tags} width={24} /> Tags</td>
             <td className='t1 w1' >:</td>
            <td className='t2 blueprojectlist'>{item.tags}</td>
          </tr></tbody>
                        )
                      })} 
                    
        </table>




          <table className=' table   table-borderless  table-striped  pl-5  col-5 ' id="wm2">
          {list.map((item,index)=>{
          return(
            <tbody key={index} >
                      <tr>
                      
                        <td className=' t w2' ><img src={Mla} width={24} /> &nbsp; M.L.A Constituency </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.mla_constituency}</td>
                      </tr>
                      <tr>
                      
                        <td className=' t w2' ><img src={mp} width={24} />&nbsp;  M.P Constituency </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.mp_constituency}</td>
                      </tr>
                      <tr>
                        
                        <td className=' t w2' ><img src={Gono} width={24} />&nbsp; GO No.</td>
                         <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.go_number}</td>
                      </tr>
                      <tr>
                        
                        <td className=' t w2' ><img src={GO} width={24} />&nbsp; GO Issue Date </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.go_issue_date}</td>
                      </tr>
                      <tr>
                        
                        <td className=' t w2' ><img src={date} width={24}/>&nbsp; Date of Sanction   </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.date_of_sanction}</td>
                      </tr>
                      <tr>
                        
                        <td className=' t w2' ><img src={Actualstart} width={24} />&nbsp; Actual Start Date      </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.actual_start_date}</td>
                      </tr>
                      <tr>
                        
                        <td className=' t w2' ><img src={Actualcomp} width={24} />&nbsp; Actual Completion Date</td>
                        <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.completion_date}</td>
                      </tr>
                      <tr>
                        
                        <td className=' t w2' ><img src={date} width={24}/>&nbsp; ECC Date</td>
                         <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.ecc_date}</td>
                      </tr>
                      <tr>
                        
                        <td className=' t w2' ><img src={date} width={24}/>&nbsp; CC Date   </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.cc_date}</td>
                      </tr>
                      <tr >
                        
                        <td className=' t w2' ><img src={Remark} width={24} />&nbsp;  Remarks</td>
                         <td className='t1 w1' >:</td>
                        <td className='t2 blueprojectlist'>{item.remarks}</td>
                      </tr></tbody>
          )

})}
                    </table>

          
      </div></div>


{/* <div>
<div className='container'>
  <div  className=' row' >
  <div className='col-3 pr-0'>
         <Chartseach/></div>
       <div className='col-9'>  <WorkStatusChart/></div> </div> </div></div> */}



      
    </div>

  );
}

export default WeareHere;
