import React, { useState, useEffect, useRef } from "react";
import { RxSlash } from "react-icons/rx";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import gimg from "../../assets/brickdemoicons/gimg.png";
import CustomToastContainer from "../../CustomToastContainer";
import { Accordion } from "react-bootstrap";
import { Transfer } from "antd";
import { data } from "jquery";
import { DualListSelector } from "@patternfly/react-core";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FaAnglesDown, FaAnglesUp } from "react-icons/fa6";
import './Siteupdatestyle.css'
function Siteupdates() {
  const [mockdata, setMockData] = useState([]);
  const [targetkeys, setTargetKeys] = useState([]);
  const [tableShow, setTableShow] = useState(false);
  const [tableids, setTableids] = useState([]);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const [err, setErr] = useState("");
  const userData = useSelector((state) => state.userDetails);
  // const [chainage] = useState([]);
  const [digiter, setDigiter] = useState("")
  const [filteredChainage, setFilteredChainage] = useState([]);
  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });
  const [messageList, setMessageList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [availableOptions, setAvailableOptions] = useState([

  ]);
  const [itemofworklist, setItemofworklist] = useState([
  ]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAvailableOptions, setSelectedAvailableOptions] = useState([]);
  const [selectedSelectedOptions, setSelectedSelectedOptions] = useState([]);
  const [availableSearch, setAvailableSearch] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isSecondChecked, setIsSecondChecked] = useState(false);
  const [isThirdChecked, setIsThirdChecked] = useState(false);
  const [tabledata, setTabledata] = useState([]);




  // Handle the "Select All" checkbox change
  //  const handleAllChange = (e) => {
  //   const isChecked = e.target.checked;
  //   setIsAllChecked(isChecked);
  //   setIsSecondChecked(isChecked);
  //   setIsThirdChecked(isChecked);
  // };

  // Handle the second checkbox change
  const handleSecondChange = (e) => {
    const isChecked = e.target.checked;
    setIsSecondChecked(isChecked);
    setIsThirdChecked(isChecked)
    // Update "Select All" checkbox based on individual checkboxes
    setIsAllChecked(isChecked && isThirdChecked);
  };

  // Handle the third checkbox change
  const handleThirdChange = (e) => {
    const isChecked = e.target.checked;
    setIsThirdChecked(isChecked);

    // Update "Select All" checkbox based on individual checkboxes
    setIsAllChecked(isSecondChecked && isChecked);
  };

  const handleMoveAllRight = () => {
    setSelectedOptions([...selectedOptions, ...availableOptions]);
    setAvailableOptions([]);
  };

  const handleMoveAllLeft = () => {
    setAvailableOptions([...availableOptions, ...selectedOptions]);
    setSelectedOptions([]);
  };

  const handleMoveRight = () => {
    setAvailableOptions(
      availableOptions.filter(
        (option) => !selectedAvailableOptions.includes(option)
      )
    );
    setSelectedOptions([...selectedOptions, ...selectedAvailableOptions]);
    setSelectedAvailableOptions([]); // Clear selected options after moving
  };

  const handleMoveLeft = () => {
    setSelectedOptions(
      selectedOptions.filter(
        (option) => !selectedSelectedOptions.includes(option)
      )
    );
    setAvailableOptions([...availableOptions, ...selectedSelectedOptions]);
    setSelectedSelectedOptions([]); // Clear selected options after moving
  };

  const handleAvailableChange = (e) => {
    const selectedValues = [...e.target.selectedOptions].map(
      (option) => option.value
    );
    setSelectedAvailableOptions(selectedValues);
  };

  const handleSelectedChange = (e) => {
    const selectedValues = [...e.target.selectedOptions].map(
      (option) => option.value
    );
    setSelectedSelectedOptions(selectedValues);
  };

  const filteredAvailableOptions = availableOptions.filter((option) =>
    option.toLowerCase().includes(availableSearch.toLowerCase())
  );

  // const filteredSelectedOptions = selectedOptions.filter((option) =>
  //   option.toLowerCase().includes(selectedSearch.toLowerCase())
  // );

  // Initialize state
const [filteredSelectedOptions, setFilteredSelectedOptions] = useState([]);

// Update filteredSelectedOptions whenever selectedOptions or selectedSearch changes
useEffect(() => {
  const filteredOptions = selectedOptions.filter((option) =>
    option.toLowerCase().includes(selectedSearch.toLowerCase())
  );
  setFilteredSelectedOptions(filteredOptions);
}, [selectedOptions, selectedSearch]);

// Now you can use filteredSelectedOptions as a state variable in your component

  const previousLength = useRef(filteredSelectedOptions.length);

  useEffect(() => {

    if (filteredSelectedOptions.length != previousLength.current ) {


      //  axios({
      //         method: 'post',

      //         url: baseurl+'/api/site_updates/get/str_or_layer_ids_by_item_of_works',

      //         headers: {
      //           'Content-Type': 'multipart/form-data',
      //          // Authorization: 'Bearer ' + userData.token
      //         }
      //       })

      const data = {
        "project_id": userData.projectId,
        "type_of_work": formData.type,
        "strs_or_layers": formData.structure,
        "item_of_works": filteredSelectedOptions

      }

      const apiUrl = `${baseurl}/api/site_updates/get/str_or_layer_ids_by_item_of_works`;

      axios
        .post(apiUrl, data, {
          headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer' + ' ' + userData.engineertoken
          },
        })


        .then((response) => {

          ////////console.log(response.data)
          const data = response.data
          const stlyrs = data.str_or_layer_ids_data

          setTabledata(stlyrs)

          ////console.log("Table data" + JSON.stringify(stlyrs))
          ////console.log(JSON.stringify(filteredSelectedOptions))
        })
        .catch((error) => {

          ////////console.log(error)
          // setErrormsg(error.response.data.message)
        })
    }
    previousLength.current = filteredSelectedOptions.length;
  }, [filteredSelectedOptions])



  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessageList([]);
    
    
    if (name === "from") {
      
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
       setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          } else {
            setErr("");
          }
        }

        return updatedData;
      });
    }

    if (e.target.name === "from") {
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );
      setFilteredChainage(filtered);
    }

    //type of work , http://localhost:8080/brick/api/work_details/get/type_of_work?project_id=PR-2&start_chainage=2.03&end_chainage=5.7
    if (formData.from != "" && formData.from1 != "") {
      if (
        e.target.name === "to1" ||
        formData.to1 != "" ||
        (e.target.value != "" && formData.to != "")
      ) {
        if (formData.to != "" && formData.from != "" && formData.from1 != "") {
          axios
            .get(`${baseurl}/api/work_details/get/type_of_work`, {
              params: {
                project_id: userData.projectId,
                start_chainage: `${formData.from}.${formData.from1}`,
                end_chainage: `${formData.to}.${formData.to1}`,
              },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              const type_of_work_list = data.type_of_work_list;
              setTypeOfWork(type_of_work_list);
              // //////////alert("type of work", type_of_work_list);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      }
    }

    //structure //http://localhost:8080/brick/api/work_details/get/strs_or_layers?project_id=PR-2&type_of_work=TYPE1
    if (e.target.name === "type" && e.target.value != "") {
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
          //////console.log(strs_or_layers_list);
          // //////////alert(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const validate = () => {
    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      setTableShow(true);
      setErr("");
    }
  };
  // function updateStatus(tid)
  // {
  //   //////////////alert(tid)
  //   setTableids(prevOptions => [...prevOptions, tid]);

  // }

  // const updateStatus = (tableId, checked,itemofwork) => {
  //   setTableids(prev => {
  //     const updatedIds = new Set(prev);
  //     if (checked) {
  //       updatedIds.add(tableId);
  //     } else {
  //       updatedIds.delete(tableId);
  //     }
  //     return Array.from(updatedIds);
  //   });
  // };
  const updateStatus = (tableId, checked, itemofwork) => {
    setTableids(prev => {
      // Filter out the item that matches both tID and item_of_work
      let updatedList = prev.filter(item => !(item.tID === tableId && item.item_of_work === itemofwork));
  
      // If the checkbox is checked, add the new item to the list
      if (checked) {
        updatedList = [...updatedList, { tID: tableId, item_of_work: itemofwork }];
      }
  
      //////alert(JSON.stringify(updatedList))
      return updatedList;
    });
  };
  
  
  
  const handleItemCheckboxChange = (key, checked) => {
    const layers = tabledata.find(item => Object.keys(item)[0] === key)[key];
    layers.forEach(layer => {
      updateStatus(layer.table_id, checked,key);
    });
  };

  function refreshItemofworklist()
  {
    axios
      .get(baseurl + '/api/work_details/get/item_of_works?project_id=' + userData.projectId + '&type_of_work=' + formData.type + '&strs_or_layers=' + formData.structure, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      .then((response) => {
        //////////console.log(response.data)

        const data = response.data

        setAvailableOptions(data.item_of_work_list)
      })
      .catch((error) => {
        //////////console.log(error)


      })
  }
  const onApproval = (e) => {
    e.preventDefault();

    //////////////alert(JSON.stringify(tableids))
    const data = {
      approval_status: 2,
      updation_data: tableids


    }
//////alert(JSON.stringify(tableids))
    /////////////alert("Process"+JSON.stringify(data))
    const apiUrl = `${baseurl}/api/site_updates/update/approval_status?project_id=`+userData.projectId;

    axios
      .put(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer' + ' ' + userData.engineertoken
        },
      })


      .then((response) => {

        ////////console.log(response.data)
        const data = response.data
////alert("success")
        ////console.log(JSON.stringify(tableids))

        setTableids([])
        setIsAllChecked(false);
        setTabledata([])
    setFilteredSelectedOptions([])
setSelectedOptions([])
setSelectedSearch("")
refreshItemofworklist()
      })
      .catch((error) => {

        ////////console.log(error)
        // setErrormsg(error.response.data.message)
      })

    // setTableids([])
    // setIsAllChecked(false);
  }

  const onRejected = (e) => {
    e.preventDefault();

    const data = {
      approval_status: 0,
      updation_data: tableids


    }


    const apiUrl = `${baseurl}/api/site_updates/update/approval_status?project_id=`+userData.projectId;

    axios
      .put(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer' + ' ' + userData.engineertoken
        },
      })


      .then((response) => {

        ////////console.log(response.data)
        const data = response.data
        //  //////////////alert("rejected")
        setTableids([])
        setIsAllChecked(false);
        setTabledata([])
        setFilteredSelectedOptions([])
        setSelectedOptions([])
        setSelectedSearch("")
        refreshItemofworklist()
      })
      .catch((error) => {

        ////////console.log(error)
        // setErrormsg(error.response.data.message)
      })

    // setTableids([])
    // setIsAllChecked(false);
  }

  const ontobeViewed = (e) => {
    e.preventDefault();

    const data = {
      approval_status: 1,
      updation_data: tableids


    }


    const apiUrl = `${baseurl}/api/site_updates/update/approval_status?project_id=`+userData.projectId;

    axios
      .put(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer' + ' ' + userData.engineertoken
        },
      })


      .then((response) => {

        ////////console.log(response.data)
        const data = response.data
        //////////////alert("tobeviewed")
        setTableids([])
        setIsAllChecked(false);
        setTabledata([])
        setFilteredSelectedOptions([])
        setSelectedOptions([])
setSelectedSearch("")
refreshItemofworklist()
      })
      .catch((error) => {

        ////////console.log(error)
        // setErrormsg(error.response.data.message)
      })

    // setTableids([])
    // setIsAllChecked(false);
  }





  const handleSubmit = (e) => {
    setMessageList([]);

    e.preventDefault();
    validate();


    axios
      .get(baseurl + '/api/work_details/get/item_of_works?project_id=' + userData.projectId + '&type_of_work=' + formData.type + '&strs_or_layers=' + formData.structure, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      .then((response) => {
        //////////console.log(response.data)

        const data = response.data

        setAvailableOptions(data.item_of_work_list)
      })
      .catch((error) => {
        //////////console.log(error)


      })


  };
  const handleAllChange = (e) => {
    const checked = e.target.checked;
    setIsAllChecked(checked);
  
    const allTableIds = [];
  
    // Loop through each item in tabledata
    tabledata.forEach(item => {
      const itemofwork = Object.keys(item)[0];  // Get the key which represents itemofwork
      const layers = item[itemofwork];
  
      // Loop through each layer
      layers.forEach(layer => {
        if (checked) {
          // Push an object with both table_id and item_of_work to allTableIds
          allTableIds.push({ tID: layer.table_id, item_of_work: itemofwork });
        }
        // Update the status for each checkbox
        updateStatus(layer.table_id, checked, itemofwork);
      });
    });
  //////alert(JSON.stringify(allTableIds))
    // Update the tableids state with the new array containing both tID and item_of_work
    setTableids(checked ? allTableIds : []);  // If unchecked, reset tableids to an empty array
  };
  


  const [isChecked, setisChecked] = useState("");
  const [input2, setInput2] = useState("");

  // Handle change for multiple inputs
  const handleInputChange = () => {
    setisChecked(!isChecked);
  };
  const handleInput1Change = (event) => {
    setInput2(event.target.checked);
    // ////////////////alert(event.target.value);
  };

  const options = [
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "three", label: "Three" },
  ];

  const [selected, setSelected] = useState([]);

  return (
    <>
      <CustomToastContainer messageList={messageList} />

      <div
        className=" container-fluid w-100 method-cont ml-4 "
        style={{
          position: "absolute",
          top: "145%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="row sh border bg-white mx-auto  pt-3 pb-3 rounded-pill border"
          style={{ maxWidth: "85%" }}
        >
          <div className="col-12 "></div>
          <div className="col-12  ">
            <div className="container ">
              <div className="row">
                <div className="col-1">
                  <label
                    className="form-label mb-0 mt-1"
                    style={{ fontWeight: "500", fontSize: "15px" }}
                  >
                    Chainage&nbsp;
                  </label>
                </div>
                <div className=" col-lg-5  ">
                  <div className="w-100  p-0">
                    <div className="row ">
                      <div className="col-lg-6 ">
                        <div class=" input-group">
                          <label
                            className="form-label mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            From :&nbsp;
                          </label>
                          <select
                            onChange={handleChange}
                            value={formData.from}
                            name="from"
                            className="form-control border rounded-0"
                            style={{
                              // height: "30px",
                              width: "30px",
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {userData.chainageArr != undefined &&
                              userData.chainageArr.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>

                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="from1"
                            type="text"
                            value={formData.from1}
                            onChange={handleChange}
                            className="form-control border rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div class="input-group">
                          <label
                            className="form-label  mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            To :&nbsp;
                          </label>

                          <select
                            name="to"
                            onChange={handleChange}
                            value={formData.to}
                            className="border form-control rounded-0"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {formData.from1 !== "" &&
                              filteredChainage.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0 rounded-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="to1"
                            type="text"
                            value={formData.to1}
                            onChange={handleChange}
                            className=" border form-control rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 px-0 ">
                  <div className=" mx-auto w-100 text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                      className=" border form-control rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Type of Work...</option>
                      {typeOfWork.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 ">
                  <div className="mx-auto w-100 text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="structure"
                      onChange={handleChange}
                      value={formData.structure}
                      className=" border form-control rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Structure/Layer</option>
                      {structure.map((item, index) => (
                        <option key={index} value={item === "Structures And Layers" ? "All" : item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-center">
                  <div className="col-1"></div>

                  <button
                    disabled={tableShow ? true : false}
                    onClick={digiter == '' ? handleSubmit : ""}
                    className="btn text-center text-white rounded-pill mt-0 "
                    style={{
                      backgroundColor: "#1953AA",
                      fontSize: "14px",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-1"></div>
              {<div className="text-red er"><center>{digiter}</center></div>}
            </div>
          </div>

        </div>

        {tableShow && (
          <div
            className="  border mx-auto p-2 rounded "
            style={{
              position: "absolute",
              top: "420%",
              left: "47%",
              transform: "translate(-50%,-50%)",
              width: "100%",
              height: "420px",
            }}
          >
            {/* <div
            className=" "
            style={{
              background: "linear-gradient(to right,#4889CF,#144A9A)",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h6 className="text-center p-2 mb-0 text-white">Status Update</h6>
          </div> */}
            <div className="row mx-0   ">
              <div className="col-4    ">
                {/* <input value={''} 
                      className="form-control  bg-transparent text-black "
                     
                      placeholder="Keyword search"
                      type="search"
                    /> */}
                <div className=" mx-auto  ">
                  {/* <Transfer
                className="bg-white pl-3"

                style={{display:'flex' ,flexDirection:'column'}}
                
                dataSource={mockdata}
                showSearch
                listStyle={{
                  width:350,
                  height:190,
                }}
                // operations={["Right" , "Left"]}
                targetKeys={targetkeys}
                onChange={handleKeyChange}
                render={(item) =>   `${item.title}`  }/> */}

                  <div
                    className="flex-column mx-auto "
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="w-100 mx-auto ">
                      <h6
                        className="ml-4"
                        style={{
                          fontSize: "14px",
                          color: "#965100",
                          fontWeight: "600",
                        }}
                      >
                        Total Item Of Work:
                      </h6>
                      <input placeholder="Search..."
                        value={availableSearch}
                        onChange={(e) => setAvailableSearch(e.target.value)}
                        type="text"
                        className="form-control mx-auto mb-2"
                        style={{ height: "30px", width: "85%" }}
                      />
                      <select
                        className="form-control mx-auto"
                        id="available"
                        multiple
                        size={5}
                        style={{ width: "85%" }}
                        onChange={handleAvailableChange}
                        value={selectedAvailableOptions}
                      >
                        {filteredAvailableOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div
                      className="flex-row  w-100 p-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // height: "50px"
                      }}
                    >
                      <button
                        style={{
                          backgroundColor:
                            selectedAvailableOptions.length > 0
                              ? "#1677ff"
                              : "#EFEFEF",
                        }}
                        className="btn btn-sm border  mr-2 h-50"
                        onClick={handleMoveRight}
                      >
                        <FaAngleDown
                          style={{
                            color:
                              selectedAvailableOptions.length > 0
                                ? "white"
                                : "#919191",
                          }}
                        />
                      </button>
                      <button
                        className="btn btn-sm border  mr-2 h-50"
                        onClick={handleMoveAllRight}
                        style={{ backgroundColor: "#EFEFEF" }}
                      >
                        <FaAnglesDown
                          style={{ fontWeight: "lighter", color: "#919191" }}
                        />
                      </button>
                      <button
                        style={{
                          backgroundColor:
                            selectedSelectedOptions.length > 0
                              ? "#1677ff"
                              : "#EFEFEF",
                        }}
                        onClick={handleMoveLeft}
                        className="btn btn-sm  border  mr-2 h-50"
                      >
                        <FaAngleUp
                          style={{
                            fontWeight: "lighter",
                            color:
                              selectedSelectedOptions.length > 0
                                ? "white"
                                : "#919191",
                          }}
                        />
                      </button>
                      <button
                        style={{ backgroundColor: "#EFEFEF" }}
                        className="btn btn-sm border  mr-2 h-50"
                        onClick={handleMoveAllLeft}
                      >
                        <FaAnglesUp
                          style={{ fontWeight: "lighter", color: "#919191" }}
                        />
                      </button>
                    </div>

                    <div className="w-100 mx-auto">
                      <h6
                        className="ml-4"
                        style={{
                          fontSize: "14px",
                          color: "#965100",
                          fontWeight: "600",
                        }}
                      >
                        Chosen Item Of Work:
                      </h6>

                      <input placeholder="Search..."
                        value={selectedSearch}
                        onChange={(e) => setSelectedSearch(e.target.value)}
                        type="text"
                        className="form-control mb-2   mx-auto"
                        style={{ height: "30px", width: "85%" }}
                      />
                      <select
                        value={selectedSelectedOptions}
                        onChange={handleSelectedChange}
                        id="selected"
                        className="form-control  mx-auto"
                        multiple
                        size={5}
                        style={{ width: "85%" }}
                      >
                        {filteredSelectedOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-7 px-0 mx-auto  sh border " style={{}}>
                <div className="d-flex justify-content-between align-items-center  mt-2 ">
                  <div className=" ml-3 ">
                    <label
                      className="mb-0 d-inline-flex justify-content-around align-items-center "
                      style={{ fontSize: "14px", fontWeight: "600" }}
                    >
                   {tabledata.length !== 0 && (
    <>
      <input 
        type="checkbox" 
        checked={isAllChecked} 
        onChange={handleAllChange} 
      />
      &nbsp; <span>Select All</span>
    </>
  )}
                    </label>
                  </div>
                  <div className="  ">
                    <button
                      className="mr-2 btn text-white"
                      style={{
                        backgroundColor: "#0A7D7A",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}

                      onClick={onApproval}
                    >
                      Approved
                    </button>
                    <button
                      className="mr-2 btn text-white"
                      style={{
                        backgroundColor: "#ED7A26",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                      onClick={ontobeViewed}
                    >
                      To Be Viewed
                    </button>
                    <button
                      className="mr-2 btn text-danger border border-danger"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                      onClick={onRejected}
                    >
                      Reject
                    </button>
                  </div>
                </div>

                {/* <Accordion
                defaultActiveKey="0 "
                className=" scrollable-div border-0 mt-2"
                style={{ height: "350px" }}
              >
                <Accordion.Item className="" eventKey="0">
                  <Accordion.Button
                    className="w-100 siteacc d-inline-flex justify-content-around p-2 align-items-center border "
                    style={{ fontSize: "15px", backgroundColor: "#EFEFEF" }}
                  >
                    
                    <input type="checkbox" checked={isSecondChecked}
          onChange={handleSecondChange}
 />
                    &nbsp;&nbsp; Item Of Work:
                    <span style={{ color: "#006A2A" }}>Earthwork</span>{" "}
                  </Accordion.Button>
                  <Accordion.Body
                    className="scrollable-div"
                    style={{ height: "270px" }}
                  >
                    <div className="col-12 ">
                      <div className="row  " style={{ fontSize: "13px" }}>
                        <table className="table col-9  table-borderless m-0  rounded-bottom ">
                          <tbody className="  ">
                            <tr className="trhis  ">
                              <td className="p-0 align-middle ">
                                {" "}
                                <input className="" type="checkbox" checked={isThirdChecked}
          onChange={handleThirdChange}
 />
                                &nbsp;&nbsp;&nbsp;
                              </td>
                              <th
                                className="thhis   "
                                style={{ lineHeight: "25px" }}
                              >
                                Layer Id
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                :
                              </th>

                              <td
                                className=" tdhis "
                                style={{ color: "#095091" }}
                              >
                                Type 1 - Earthwork-FP
                              </td>
                            </tr>

                            <tr className="trhis  " style={{ rowGap: "2px" }}>
                              <td className="p-0    "> </td>
                              <th colSpan={2} className="thhis pt-2  ">
                                Testing Dependency
                              </th>
                              <td></td>
                            </tr>
                            <tr className=" ">
                              {" "}
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Test Name &nbsp; :
                              </th>
                              <td className=" tdhis ">238.2</td>
                            </tr>
                            <tr>
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Result&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                :
                              </th>
                              <td className=" tdhis">238.2</td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          className="col-3"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Gallery Image&nbsp; :{" "}
                          <button className="btn p-0">
                            <img src={gimg} width={25} />{" "}
                          </button>{" "}
                        </div>
                      </div>
                      <hr />
                      <div className="row  " style={{ fontSize: "13px" }}>
                        <table className="table col-9  table-borderless m-0  rounded-bottom ">
                          <tbody className="  ">
                            <tr className="trhis  ">
                              <td className="p-0 align-middle ">
                                {" "}
                                <input className="" type="checkbox" />
                                &nbsp;&nbsp;&nbsp;
                              </td>
                              <th
                                className="thhis   "
                                style={{ lineHeight: "25px" }}
                              >
                                Layer Id
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                :
                              </th>

                              <td
                                className=" tdhis "
                                style={{ color: "#095091" }}
                              >
                                Type 1 - Earthwork-FP
                              </td>
                            </tr>

                            <tr className="trhis  " style={{ rowGap: "2px" }}>
                              <td className="p-0    "> </td>
                              <th colSpan={2} className="thhis pt-2  ">
                                Testing Dependency
                              </th>
                              <td></td>
                            </tr>
                            <tr className=" ">
                              {" "}
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Test Name &nbsp; :
                              </th>
                              <td className=" tdhis ">238.2</td>
                            </tr>
                            <tr>
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Result&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                :
                              </th>
                              <td className=" tdhis">238.2</td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          className="col-3"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Gallery Image&nbsp; :{" "}
                          <button className="btn p-0">
                            <img src={gimg} width={25} />{" "}
                          </button>{" "}
                        </div>
                      </div>
                      <hr />
                      <div className="row  " style={{ fontSize: "13px" }}>
                        <table className="table col-9  table-borderless m-0  rounded-bottom ">
                          <tbody className="  ">
                            <tr className="trhis  ">
                              <td className="p-0 align-middle ">
                                {" "}
                                <input className="" type="checkbox" />
                                &nbsp;&nbsp;&nbsp;
                              </td>
                              <th
                                className="thhis   "
                                style={{ lineHeight: "25px" }}
                              >
                                Layer Id
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                :
                              </th>

                              <td
                                className=" tdhis "
                                style={{ color: "#095091" }}
                              >
                                Type 1 - Earthwork-FP
                              </td>
                            </tr>

                            <tr className="trhis  " style={{ rowGap: "2px" }}>
                              <td className="p-0    "> </td>
                              <th colSpan={2} className="thhis pt-2  ">
                                Testing Dependency
                              </th>
                              <td></td>
                            </tr>
                            <tr className=" ">
                              {" "}
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Test Name &nbsp; :
                              </th>
                              <td className=" tdhis ">238.2</td>
                            </tr>
                            <tr>
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Result&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                :
                              </th>
                              <td className=" tdhis">238.2</td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          className="col-3"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Gallery Image&nbsp; :{" "}
                          <button className="btn p-0">
                            <img src={gimg} width={25} />{" "}
                          </button>{" "}
                        </div>
                      </div>
                      <hr />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item className="" eventKey="1">
                  <Accordion.Button
                    className="w-100 siteacc d-inline-flex justify-content-around p-2 align-items-center border "
                    style={{ fontSize: "15px", backgroundColor: "#EFEFEF" }}
                  >
                    {" "}
                    <input type="checkbox" />
                    &nbsp;&nbsp; Item Of Work:
                    <span style={{ color: "#006A2A" }}>Earthwork</span>{" "}
                  </Accordion.Button>
                  <Accordion.Body>
                    <div className="col-12 ">
                      <div className="row  " style={{ fontSize: "13px" }}>
                        <table className="table col-8  table-borderless m-0  rounded-bottom ">
                          <tbody className="  ">
                            <tr className="trhis  ">
                              <td className="p-0 align-middle   ">
                                {" "}
                                <input className="" type="checkbox" />
                                &nbsp;&nbsp;&nbsp;
                              </td>
                              <th
                                className="thhis   "
                                style={{ lineHeight: "25px" }}
                              >
                                Layer Id
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                :
                              </th>

                              <td
                                className=" tdhis "
                                style={{ color: "#095091" }}
                              >
                                Type 1 - Earthwork-FP
                              </td>
                            </tr>

                            <tr className="trhis  " style={{ rowGap: "2px" }}>
                              <td className="p-0    "> </td>
                              <th colSpan={2} className="thhis pt-2  ">
                                Testing Dependency
                              </th>
                              <td></td>
                            </tr>
                            <tr className=" ">
                              {" "}
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Test Name &nbsp; :
                              </th>
                              <td className=" tdhis ">238.2</td>
                            </tr>
                            <tr>
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Result&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                              </th>
                              <td className=" tdhis">238.2</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />

                      <div className="row  " style={{ fontSize: "13px" }}>
                        <table className="table col-8  table-borderless m-0  rounded-bottom ">
                          <tbody className="  ">
                            <tr className="trhis  ">
                              <td className="p-0 align-middle   ">
                                {" "}
                                <input className="" type="checkbox" />
                                &nbsp;&nbsp;&nbsp;
                              </td>
                              <th
                                className="thhis   "
                                style={{ lineHeight: "25px" }}
                              >
                                Layer Id
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                :
                              </th>

                              <td
                                className=" tdhis "
                                style={{ color: "#095091" }}
                              >
                                Type 1 - Earthwork-FP
                              </td>
                            </tr>

                            <tr className="trhis  " style={{ rowGap: "2px" }}>
                              <td className="p-0    "> </td>
                              <th colSpan={2} className="thhis pt-2  ">
                                Testing Dependency
                              </th>
                              <td></td>
                            </tr>
                            <tr className=" ">
                              {" "}
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Test Name &nbsp; :
                              </th>
                              <td className=" tdhis ">238.2</td>
                            </tr>
                            <tr>
                              <td></td>
                              <th
                                className="thhis "
                                style={{ lineHeight: "25px" }}
                              >
                                Result&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                              </th>
                              <td className=" tdhis">238.2</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}


                <Accordion defaultActiveKey="0" className="scrollable-div border-0 mt-2" style={{ height: "350px" }}>
                  {tabledata.length != 0 && tabledata.map((item, index) => {
                    const key = Object.keys(item)[0];
                    const layers = item[key];

                    // const handleItemCheckboxChange = (checked) => {
                    //     layers.forEach(layer => {
                    //       updateStatus(layer.table_id, checked);
                    //     });
                    //   };

                    return (
                      <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Button className="w-100 siteacc d-inline-flex justify-content-around p-2 align-items-center border" style={{ fontSize: "15px", backgroundColor: "#EFEFEF" }}>
                          <div className="checkbox-text-grid">
                            <input
                              type="checkbox"
                              onChange={(e) => handleItemCheckboxChange(key, e.target.checked)}
                            checked={layers.every(layer => 
  tableids.some(item => item.tID === layer.table_id && item.item_of_work ===key)
)}

                            />
                            <span className="item-of-work-text">&nbsp;&nbsp;Item Of Work: <span style={{ color: "#006A2A" }}>{key}</span></span>
                          </div>
                        </Accordion.Button>
                        <Accordion.Body className="scrollable-div" style={{ height: "270px" }}>
                          {layers.map((layer, layerIndex) => (
                            <div key={layerIndex} className="col-12">
                              <div className="row" style={{ fontSize: "13px" }}>
                                <table className="table  table-borderless ">
                                  <tbody >

                                    {(formData.structure === "Layers" || formData.structure === "All") &&
                                      <tr  className="minimized-row">
                                       <td style={{ width: '20px', verticalAlign: 'top', paddingTop: '5px', margin: '0' }}>
                                       <input 
  type="checkbox"
  checked={tableids.some(item => item.tID === layer.table_id && item.item_of_work === key)}
  onChange={(e) => updateStatus(layer.table_id, e.target.checked, key)}
  style={{ verticalAlign: 'top', margin: '0', padding: '0' }} 
/>

</td>


                                        <td style={{ textAlign: 'right' }}>
                                          Layer Id
                                        </td>
                                        <td style={{ color: "#095091", textAlign: 'left' }} class="text-start">
                                          :&nbsp;{layer.layer_id.replace(/-/g, ' ')}
                                        </td>

                                        <td style={{ textAlign: 'right' }}>
                                          Gallery Image &nbsp;:
                                        </td>
                                        <td style={{ width: '5%', verticalAlign: 'top', paddingTop: '5px', margin: '0' }}>
                                      
                                        {layer.progress_image_url.length !== 0 ? (
    <img src={layer.progress_image_url[0]} width={25} alt="Gallery" style={{ verticalAlign: 'top' }} />
  ) : (
    <img src={gimg} width={25} style={{ verticalAlign: 'top' }} />
  )}
                                     
                                        </td>

                                      </tr>
                                    }

                                    {formData.structure === "Structures" &&
                                      <tr className="minimized-row">
                                        <td>
                                          <input type="checkbox"

                                            checked={tableids.includes(layer.table_id)}
                                            onChange={(e) => updateStatus(layer.table_id, e.target.checked,key)} />
                                        </td>

                                        <td style={{ textAlign: 'right' }}>
                                          Structure Id
                                        </td>
                                        <td style={{ color: "#095091", textAlign: 'left' }}>
                                         :&nbsp; {layer.str_id.replace(/-/g, ' ')}
                                        </td>

                                        <td style={{ textAlign: 'right' }}>
                                          Gallery Image
                                        </td>
                                        <td style={{ color: "#095091", textAlign: 'left' }}>
                                          <button className="">
                                            :&nbsp;{layer.progress_image_url.length != 0 ?
                                              <img src={layer.progress_image_url[0]} width={25} alt="Gallery" /> :
                                              <img src={gimg} width={25} />}
                                          </button>
                                        </td>

                                      </tr>
                                    }


                                    {formData.structure === "All" &&
                                      <tr className="minimized-row" >
                                        <td>

                                        </td>

                                        <td style={{ textAlign: 'right' }}>
                                          Structure Id
                                        </td>
                                        <td colSpan={3} style={{ color: "#095091", textAlign: 'left' }}>
                                          :&nbsp;{layer.str_id.replace(/-/g, ' ')}
                                        </td>




                                      </tr>
                                    }



                                    <tr className="minimized-row">
                                      <td></td>
                                      <td style={{ textAlign: 'left' }} colSpan={2}> <b>
                                        
                                     {layer.str_id!="-" && <center>Testing Dependency</center>}
                                     {layer.str_id=="-" && "Testing Dependency"}
                                        
                                        </b>  </td>
                                      <td colSpan={3}></td>
                                    </tr>
                                    <tr  className="minimized-row">
                                      <td></td>
                                      <td style={{ textAlign: 'right' }}>  Test Name  </td>
                                      <td style={{ textAlign: 'left' }} colSpan={3}>:&nbsp;{layer.test_name[0]} </td>

                                    </tr>

                                    <tr  className="minimized-row">
                                      <td></td>
                                      <td style={{ textAlign: 'right' }}>  Result </td>
                                      <td style={{ textAlign: 'left' }} colSpan={3}>:&nbsp;{layer.test_result[0]}</td>

                                    </tr>

                                  </tbody>
                                </table>
                                {/* <div className="col-4" style={{ fontSize: "14px", fontWeight: "600" }}>
                      Gallery Image&nbsp;:
                      <button className="btn p-0">
                      {layer.progress_image_url.length!=0?
                        <img src={layer.progress_image_url[0]} width={25} alt="Gallery" />:
                        <img src={gimg} width={25} />}
                      </button>
                    </div> */}
                              </div>
                              {layerIndex < layers.length - 1 && <hr />}
                            </div>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>






              </div>
            </div>
          </div>
        )}

        {/* {showImg && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          animation={true}
        >
          <Modal.Body>
            <div className=" bg-white ">
              <div className="w-100 d-inline-flex justify-content-end pr-2">
                <button className="btn p-0 bg-white" onClick={handleImgClose}>
                  <IoMdClose />
                </button>
              </div>
              <div className="text-center">
                <img src={galleryImg1} alt="img" width={768} height={500} />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )} */}
      </div>
    </>
  );
}

export default Siteupdates;
