import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, CircleMarker, Polyline, useMap,Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLngBounds } from 'leaflet';
import L from 'leaflet';
import Legend from '../NewRoadReports/LegendNames'; // Import the Legend component
import { connect, useDispatch, useSelector } from 'react-redux'
import banner from '../../assets/images1/backgroundabstractwhite.png'
import quary from '../../assets/brickdemoicons/quaryicon.png'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import locat from '../../assets/brickdemoicons/locationname.png'
const SetBounds = ({ positions }) => {
  const map = useMap();

  useEffect(() => {
    if (positions.length > 0) {
      const bounds = new LatLngBounds(positions);
      map.fitBounds(bounds);
    }
  }, [positions, map]);

  return null;
};

let locationname = '';
const fetchLocationName = async () => {
  try {
    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=9.339830554686758&lon=77.98141665905612`);
    locationname = response.data.address.road || '';
    return response.data.display_name;
  } catch (error) {
    return 'Unknown location';
  }
};




const App = () => {
  const [routeData, setRouteData] = useState(null);
  const [routeResourceData, setRouteResourceData] = useState(null);
  const [error, setError] = useState(null);
  const [textIcons, setTextIcons] = useState([]);
  const [resourcelocationname, setResourcelocationname] = useState('');
  const [startlocationdata, setStartocationdata] = useState([]);
  const [locationdata, setLocationdata] = useState([]);
  const [Endlocationdata, setEndlocationdata] = useState([]);
  const [resourcelocationdata, setResourceocationdata] = useState([]);
  const [resourcebackenddata, setResourcebackenddata] = useState([]);
  const [landmarks, setLandmarks] = useState([]);
  const [status, setStatus] = useState([]);
  const userData = useSelector((state) => state.userDetails)
  

  const [resourceIcons, setResourceIcons] = useState([]);

  useEffect(() => {
    // Function to create custom icon
    const createCustomIcon = (nameOfResourceSite) => L.divIcon({
      html: `
        <div style="background: white; padding-left: 20px; margin-top: 70px; padding-bottom: 5px; border-radius: 5px; display: flex; align-items: center;">
          <img src=${quary} alt="icon" style="width: 20px; height: 20px; margin-right: 5px;" />
          <span style="color: black; font-weight: bold; font-size: 15px;">${nameOfResourceSite}</span>
        </div>
      `,
      className: 'custom-tooltip', // Add any additional custom class if needed
      iconSize: [150, 40], // Adjust size to fit content
      iconAnchor: [75, 20]  // Center the icon and adjust to be slightly above the coordinate
    });

    // Function to create the new icons
    const createIcons = () => {
      if(resourcebackenddata.length!=0){
      const newResourceIcons = resourcebackenddata.map((location) => {
        
        // Extract coordinates and name
        const lat = parseFloat(location.coordinates[1]);
        const lon = parseFloat(location.coordinates[0]);

        // Create the icon using the custom function
        const icon = createCustomIcon(location.name_of_resource_site);

        return {
          position: [lat, lon], // [Lat, Lng]
          icon: icon
        };
      });

      setResourceIcons(newResourceIcons);
    };
  }
    createIcons();
  }, [resourcebackenddata]);



  useEffect(() => {

    axios({
      method: 'GET',

      url: baseurl + '/api/get/resource/location?project_id='+userData.projectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        // Authorization: 'Bearer ' + userData.token,
      }
    })

      .then((response) => {

        ////console.log(response.data)
        const RequestData = response.data
      // const locationdetails= RequestData.location_details
//setStartocationdata(locationdetails. start_coordinates)
// setEndlocationdata(locationdetails.end_coordinates)
// setResourcelocationname(locationdetails.resource)
// //////////alert(JSON.stringify(locationdetails.resource))

// setStartocationdata([['78.01427384656027','9.311589284869399'],
//   ['77.98141665905612','9.339830554686758']])
//   setLandmarks([['9.311589284869399', '78.01427384656027'],
//     ['9.339830554686758', '77.98141665905612']])
const locationdetails= RequestData.location_details

//setStartocationdata(locationdetails. start_coordinates)
setLocationdata(locationdetails.coordinates)
////////alert(JSON.stringify(locationdetails.coordinates))
setLandmarks(locationdetails.landmark)
////console.log("landmark"+JSON.stringify(locationdetails.landmark))
////console.log("sjdjsdjkfdjkdsjjkdkk"+JSON.stringify(locationdetails.coordinates))

//setResourceocationdata(locationdetails.resource)
//const coordinatesArray = locationdetails.resource.map(resource => resource.coordinates);
//////////alert("ll"+coordinatesArray)
const startPoint = ["77.98141665905612", "9.339830554686758"];
const endPoints = [
  { "coordinates": ["9.314285", "77.998313"], "name_of_resource_site": "Cement Plant" },
  { "coordinates": ["9.343341", "77.973718"], "name_of_resource_site": "Aggregate Quarry" }
];

//////////alert("New"+JSON.stringify(newArray))
////console.log("Resource now"+JSON.stringify(locationdetails.resource))



// [{"coordinates":["9.314285","77.998313"],"name_of_resource_site":"Cement Plant"},
// {"coordinates":["9.343341","77.973718"],"name_of_resource_site":"Aggregate Quarry"}]


setResourcebackenddata(locationdetails.resource)
// working code

const projectstartingpoint= locationdetails.coordinates[0];;
////console.log("Start pont"+projectstartingpoint)
const resourcedata=locationdetails.resource
const combinedArray = resourcedata.map(resourcepoint=> [projectstartingpoint, resourcepoint.coordinates]);
////console.log("New map resource array"+combinedArray)
// Working code

//setResourceocationdata([[["9.339830554686758","77.98141665905612"],["9.314285","77.998313"]],[["9.339830554686758","77.98141665905612"],["9.343341","77.973718"]]])
setResourceocationdata(combinedArray)
// setResourceocationdata([
//      [["77.98141665905612", "9.339830554686758"], ["77.973718", "9.343341"]],
//      [["77.98141665905612", "9.339830554686758"], ["77.998313", "9.314285"]],
   
//    ])


//////////alert(JSON.stringify(locationdetails.resource))
    setStatus([
      { start: [9.334197, 77.988819], end: [9.326643, 77.989903], st: "completed" },
      { start: [9.336001, 77.987227], end: [9.335281, 77.987978], st: "inprogress" },
    ])


        ////console.log("requesttttt", RequestData)

      })
      .catch((error) => {

        ////console.log(error)
        // setErrormsg(error.response.data.message)
      })
  },[])



  useEffect(() => {
    // Function to create the new icons
    const createIcons = async () => {
      // Map through the resource backend data
      const newTextIcons = await Promise.all(resourcebackenddata.map(async (location) => {
        // Extract coordinates
        const lat = location.coordinates[1];
        const lon = location.coordinates[0];
        
        // Fetch location name
        const lname = await fetchResourceLocationName(lat, lon);
  
        // Create and return the icon
        return L.divIcon({
          html: `
            <div style="display: flex; align-items: center; justify-content: center; color: #060270; font-weight: bold; font-size: 13px; text-align: center; padding: 5px; border-radius: 5px;">
              ${lname}
            </div>`,
          className: 'text-icon',
          iconSize: [150, 50], // Adjust the size to fit the text and icon
          iconAnchor: [75, 25], // Center the icon over the circle
        });
      }));
  
      setTextIcons(newTextIcons);
    };
  
    createIcons();
  }, [resourcebackenddata]);




  useEffect(()=>
    {
      fetchRoute()
    },[locationdata])


    useEffect(()=>
      {
        // fetchRoute1()
        fetchRoutes()
      },[resourcelocationdata])


      const fetchRoutes = async () => {
        // const allPoints = [
        //   [["77.98141665905612", "9.339830554686758"], ["77.973718", "9.343341"]],
        //   [["77.98141665905612", "9.339830554686758"], ["77.998313", "9.314285"]],
         
        // ];
      
        try {
          // Initialize route data state as an empty array if it's not already an array
          setRouteResourceData([]);
      
          for (const points of resourcelocationdata) {
            const data = {
              points: points,
              // snap_preventions: ["motorway", "ferry", "tunnel"],
              // details: ["road_class", "surface"],
              snap_preventions: [],  // Try removing or adjusting snap preventions
              details: ["road_class", "surface", "max_speed"],  // Add more details
              profile: "bike",
              locale: "en",
              instructions: true,
              calc_points: true,
              points_encoded: false,
            };
      
            const response = await axios.post(
              'https://graphhopper.com/api/1/route?key=cc633506-bbbd-48a7-8552-34bb65f77592',
              data,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );
      
            setRouteResourceData(prevData => [...(prevData || []), response.data]);
           // //////alert("Resource: " + JSON.stringify(response.data));
      
            // Here you would add code to draw the route on the map using response.data
          }
        } catch (error) {
          setError(error);
          console.error('Error fetching route:', error);
        }
      };
      


      const fetchRoute1 = async () => {
        if(resourcelocationdata.length!=0)
        {
     ////////alert("2"+JSON.stringify(resourcelocationdata))
        
        const data = {
          // points:[[77.98141665905612,9.339830554686758],[78.01427384656027,9.31158928486939]],
          points:[[77.98141665905612,9.339830554686758],[77.973718,9.343341]],
          snap_preventions: ["motorway", "ferry", "tunnel"],
          details: ["road_class", "surface"],
          profile: "bike",
          locale: "en",
          instructions: true,
          calc_points: true,
          points_encoded: false,
        };
    
        try {
          const response = await axios.post(
            'https://graphhopper.com/api/1/route?key=cc633506-bbbd-48a7-8552-34bb65f77592',
            data,
            {
              headers: {

                'Content-Type': 'application/json',
              },
            }
          );
          setRouteResourceData(response.data);
          //////alert("Resource"+JSON.stringify(response.data))
        } catch (error) {
          setError(error);
          console.error('Error fetching route:', error);
        }
      };
      }
    
    const fetchRoute = async () => {
      if(locationdata.length!=0)
      {
  
        ////////alert("1"+JSON.stringify(locationdata))
      const data = {
        points: locationdata,
        snap_preventions: ["motorway", "ferry", "tunnel"],
        details: ["road_class", "surface"],
        profile: "bike",
        locale: "en",
        instructions: true,
        calc_points: true,
        points_encoded: false,
      };
  
      try {
        const response = await axios.post(
          'https://graphhopper.com/api/1/route?key=cc633506-bbbd-48a7-8552-34bb65f77592',
          data,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }

        );
        setRouteData(response.data);
        ////console.log("Location"+JSON.stringify(response.data))
      } catch (error) {
        setError(error);
        console.error('Error fetching route:', error);
      }
    };
    }
  
    // useEffect(() => {
    //   fetchLocationName();
    //   fetchResourceLocationName()
    // }, []);
  
    // const landmarks = [
    //   { position: [9.339830554686758, 77.98141665905612], name: '1/0 - 2/03' },
    //   { position: [9.334786185362356, 77.98840753441415], name: '2/03 - 4/05' },
    //   { position: [9.323883625001962, 78.00226109917587], name: '4/05 - 4/5' },
    //   { position: [9.320435948199666, 78.00444086177819], name: '4/5 - 5/05' },
    //   { position: [9.316585241063349, 78.00763898718029], name: '5/05 - 5/625' },
    //   // { position: [9.313294859453421, 78.01133578218892], name: '5/625 - 5/825' },
    //   // { position: [9.312322346960173, 78.01280307287712], name: '5/825 - 6/0' },
    // ];
 

  useEffect(() => {
    fetchLocationName();
   // fetchResourceLocationName()
  }, []);
  useEffect(() => {
 
  }, [resourceIcons]);
  // const landmarks = [
  //   { position: [9.339830554686758, 77.98141665905612], name: '1/0 - 2/03' },
  //   { position: [9.334786185362356, 77.98840753441415], name: '2/03 - 4/05' },
  //   { position: [9.323883625001962, 78.00226109917587], name: '4/05 - 4/5' },
  //   { position: [9.320435948199666, 78.00444086177819], name: '4/5 - 5/05' },
  //   { position: [9.316585241063349, 78.00763898718029], name: '5/05 - 5/625' },
  //   // { position: [9.313294859453421, 78.01133578218892], name: '5/625 - 5/825' },
  //   // { position: [9.312322346960173, 78.01280307287712], name: '5/825 - 6/0' },
  // ];

  // const status = [
  //   { start: [9.334197, 77.988819], end: [9.326643, 77.989903], st: "completed" },
  //   { start: [9.336001, 77.987227], end: [9.335281, 77.987978], st: "inprogress" },
  // ];

  const routePositions = routeData
    ? routeData.paths.flatMap(path =>
        path.points.coordinates.map(coord => [coord[1], coord[0]])
      )
    : [];
    // const routePositions1= routeResourceData
    // ? routeResourceData.paths.flatMap(path =>
    //     path.points.coordinates.map(coord => [coord[1], coord[0]])
    //   )
    // : [];

  const findWaypointIndex = (lat, lon) => {
    return routePositions.findIndex(pos => Math.abs(pos[0] - lat) < 0.001 && Math.abs(pos[1] - lon) < 0.001);
  };

  const allPositions = [
    ...routePositions,
    ...landmarks.map(landmark => landmark.position)
  ];

  const getColor = (status) => {
    switch (status) {
      case "completed":
        return "green";
      case "inprogress":
        return "orange";
      default:
        return "red";
    }
  };

  const customIcon = new L.Icon({
    iconUrl: require('../../assets/brickdemoicons/locationpin1.png'),
    iconSize: [10, 10],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });

  const startIcon = new L.Icon({
    iconUrl: require('../../assets/brickdemoicons/stlocationstart.png'),
    iconSize: [25, 41],
    iconAnchor: [4, 43], // Adjusted to anchor at the right edge of the icon
    popupAnchor: [1, -34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41]
  });

  const endIcon = new L.Icon({
    iconUrl: require('../../assets/brickdemoicons/locationend.png'),
    iconSize: [25, 41],
    iconAnchor: [25, 41], // Adjusted to anchor at the right edge of the icon
    popupAnchor: [1, -34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41]
  });

  const invisibleIcon = new L.divIcon({
    className: 'invisible-icon',
    html: '<div style="width: 0; height: 0;"></div>'
  });

  // Tolerance for coordinate comparison
  const tolerance = 0.01;

  const isCoordinateMatch = (coord1, coord2) => {
    return Math.abs(coord1[0] - coord2[0]) < tolerance && Math.abs(coord1[1] - coord2[1]) < tolerance;
  };

  // Create an array to store the segments that need to be colored red
  const uncoveredSegments = [];

  // Process routePositions to color segments
  const segmentsToRender = [];

  for (let i = 0; i < routePositions.length - 1; i++) {
    const start = routePositions[i];
    const end = routePositions[i + 1];

    const matchingSegment = status.find(
      segment =>
        isCoordinateMatch(segment.start, start) &&
        isCoordinateMatch(segment.end, end)
    );

    if (matchingSegment) {
     // ////console.log(`Matched segment: start = ${matchingSegment.start}, end = ${matchingSegment.end}, color = ${getColor(matchingSegment.st)}`);
      segmentsToRender.push({
        start,
        end,
       // color: getColor(matchingSegment.st)
       color:'green'
      });
    } else {
      uncoveredSegments.push({ start, end });
    //  ////console.log(`Unmatched segment: start = ${start}, end = ${end}, color = red`);
      segmentsToRender.push({
        start,
        end,
       // color: 'red'
       color:'green'
      });
    }
  }
  // const maduraiIndex = findWaypointIndex(9.339830554686758, 77.98141665905612);
  // const thanjavurIndex = findWaypointIndex(9.334786185362356, 77.98840753441415);
  // const kumbakonamIndex = findWaypointIndex(9.323883625001962, 78.00226109917587);
  // const chennaiIndex = findWaypointIndex(9.320435948199666, 78.00444086177819);
  // const additionalIndex1 = findWaypointIndex(9.316585241063349, 78.00763898718029);
  // const additionalIndex2 = findWaypointIndex(9.313294859453421, 78.01133578218892);
  // const additionalIndex3 = findWaypointIndex(9.312322346960173, 78.01280307287712);
  // const additionalIndex4 = findWaypointIndex(9.311589284869399, 78.01427384656027);

  // const segments = [
  //   { start: 0, end: thanjavurIndex, color: 'green' },
  //   { start: thanjavurIndex, end: kumbakonamIndex, color: 'blue' },
  //   { start: kumbakonamIndex, end: chennaiIndex, color: 'orange' },
  //   { start: chennaiIndex, end: additionalIndex1, color: 'red' },
  //   { start: additionalIndex1, end: additionalIndex2, color: 'purple' },
  //   { start: additionalIndex2, end: additionalIndex3, color: 'black' },
  //   { start: additionalIndex3, end: additionalIndex4, color: '#17BBCD' }
  // ];
  const start = [9.335102, 77.968108];
  const end = [9.339830554686758, 77.98141665905612];

  const positions = [start, end];
  //////////alert("pos"+JSON.stringify(positions))



  async function fetchResourceLocationName(lat, lon) {

    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`);
      const locationName = response.data.display_name || '';
  
      if (locationName.includes(',')) {
        const names = locationName.split(',');
        return names[0]?.trim() || '';
      } else {
        return locationName;
      }
    } catch (error) {
      console.error('Error fetching location name:', error);
      return 'Unknown location';
    }
  }



// useEffect(() => {
//   if (resourcelocationname) {
//     const newTextIcon = L.divIcon({
//       html: `<div style="color: #060270; font-weight: bold; font-size:15px; text-align: center; padding: 5px; border-radius: 5px; ">${resourcelocationname}</div>`,
//       className: 'text-icon',
//       iconSize: [150, 30], // Adjust the size to fit the text
//       iconAnchor: [75, 15], // Center the icon over the circle
//     });
//     setTextIcon(newTextIcon);
//   }
// }, [resourcelocationname]);

 // Create a custom divIcon to display the resourceLocationName
//  const textIcon = L.divIcon({
//   html: `<div style="color: #060270; font-weight: bold; font-size:20px; text-align: center;  padding: 5px; border-radius: 5px;">${resourcelocationname}</div>`,
//   className: 'text-icon',
//   iconSize: [150, 30], // Adjust the size to fit the text
//   iconAnchor: [75, 15], // Center the icon over the circle
// });


// Define the custom tooltip icon
const quarryIcon = L.divIcon({
  html: `
    <div style="background: white; padding-left: 20px; padding-top: 5px;padding-bottom: 5px;border-radius: 5px; display: flex; align-items: center;">
     <center> <img src=${quary} alt="icon" style="width: 20px; height: 20px; margin-right: 5px; " />
      <span style="color: black; font-weight: bold;font-size:15px">Quarry</span></center>
    </div>
  `,
  className: 'custom-tooltip', // Add any additional custom class if needed
  iconSize: [120, 40], // Adjust size to fit content
  iconAnchor: [60, 20]  // Center the icon
});

// Place the Marker at a specific position on the polyline
const midpoint = [
  (start[0] + end[0]) / 2,
  (start[1] + end[1]) / 2
];
const routePolylines = routeResourceData && Array.isArray(routeResourceData)
  ? routeResourceData.flatMap((route, index) => {
      // Check if route and paths exist
      if (route.paths && Array.isArray(route.paths)) {
        return route.paths.flatMap(path => {
          // Check if path and points exist
          if (path.points && Array.isArray(path.points.coordinates)) {
            const routePositions = path.points.coordinates.map(coord => [coord[1], coord[0]]);
            
            // Return Polyline for each path
            return (
              <Polyline
                key={`${index}-${path.points.coordinates.length}`}
                positions={routePositions}
                pathOptions={{ color: 'black', dashArray: '5, 10' }} // Dashed line with dashArray
              />
            );
          }
          return []; // Return empty array if points are not available
        });
      }
      return []; // Return empty array if paths are not available
    })
  : [];



  return (
    <div>
        <div className='cont-margin1' >
     
    <div class="cont-margin-latest pt-5 ">
   
   <div class="m-5 divlocationcont">
   <div class="row">
        <div class="col-12 col-lg-12 col-xl-12 projname pb-2">
        <center>{userData.projectName}</center>
        </div>
        <div class="col-4 col-lg-4col-xl-4  locationname pb-1">
        <img src={locat} />&nbsp; Irukkankudi, Madurai
        </div>
 
  </div>


  
      {error && <p>Error fetching route data: {error.message}</p>}
      <MapContainer
        center={[9.32, 78.0]}
        zoom={10}
        style={{ height: '70vh', width: '100%' ,borderRadius:'20px'}}
        zoomControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
       {/* {resourcelocationdata.length !== 0 && resourcelocationdata.map((position, index) => (
  <Polyline
    key={index}
    positions={position}
    pathOptions={{ color: 'black', dashArray: '5, 10' }} // Dotted line with dashArray
  />
))} */}
  {/* {routeResourceData &&
        routeResourceData.map((position, index) => (
          <Polyline
            key={index}
            positions={position}
            pathOptions={{ color: 'black', dashArray: '5, 10' }} // Dotted line with dashArray
          />
        ))} */}

{/* {routePositions1.length > 0 && (
        <Polyline
          positions={routePositions1}
          pathOptions={{ color: 'black', dashArray: '5, 10' }} // Dashed line with dashArray
        />
      )} */}
 {routePolylines}

         {/* <Marker position={midpoint} icon={quarryIcon} />
                <Marker
          position={start}
          icon={L.divIcon({
            html: '<div style="width: 160px; height: 80px; background-color: transparent; border: 2px dashed #F1D014; border-radius: 50%;"></div>',
            className: 'oval-icon',
            iconSize: [160, 80],
            iconAnchor: [80, 40], // Center the oval
          })}
        />
          {textIcon && <Marker position={start} icon={textIcon} />} */}

{resourcelocationdata.length!=0 &&resourcelocationdata.map((locationPair, index) => {
        const start = [parseFloat(locationPair[1][1]), parseFloat(locationPair[1][0])]; // Lat, Lng for the start point
        ////console.log("Starttttttttttttt"+start)
        const end = [parseFloat(locationPair[0][1]), parseFloat(locationPair[0][0])]; // Reference point for midpoint
        const midpoint = [
          (start[0] + end[0]) / 2,
          (start[1] + end[1]) / 2
        ];

        return (
          
          <React.Fragment key={index}>
            
            <Marker
              position={start}
              icon={L.divIcon({
                html: '<div style="width: 160px; height: 80px; background-color: transparent; border: 2px dashed #F1D014; border-radius: 50%;"></div>',
                className: 'oval-icon',
                iconSize: [160, 80],
                iconAnchor: [80, 40], // Center the oval
              })}
            />

            <Marker position={midpoint} icon={L.divIcon({ html: '', className: 'midpoint-icon' })}   />
            {/* <Marker position={midpoint} icon={quarryIcon} /> */}

            {textIcons[index] && <Marker position={start} icon={textIcons[index]} />}
          </React.Fragment>
        );
      })}
        {resourceIcons.length!=0 && resourceIcons.map((iconData, index) => (
        <Marker
          key={index}
          position={iconData.position}
          icon={iconData.icon}
        />
      ))}

        {routeData && (
          <>
            <SetBounds positions={allPositions} />
            {segmentsToRender.map((segment, index) => (
              <Polyline
                key={index}
                positions={[segment.start, segment.end]}
                color={segment.color}
              />
            ))}
              {/* {segments.map((segment, index) => (
              <CircleMarker
                key={`end-segment-${index}`}
                center={routePositions[segment.end]}
                radius={5}
                fillColor="black"
                color="black"
                fillOpacity={1}
              />
            ))} */}
            <CircleMarker
              key="start-segment"
              center={routePositions[0]}
              radius={5}
              fillColor="orange" // Color for the start point of the first segment
              color="orange"
              fillOpacity={1}
            />
            <CircleMarker
              key="end-segment"
              center={routePositions[routePositions.length-1]}
              radius={5}
              fillColor="orange" // Color for the start point of the first segment
              color="orange"
              fillOpacity={1}
            />
            {/* {landmarks.map((landmark, index) => (
              <Marker key={index} position={landmark.position} icon={invisibleIcon}>
                <Tooltip
                  direction="left"  // Tooltip appears at the bottom of the marker
                  offset={[-20, 10]}  // Adjust the offset to move the tooltip down
                  opacity={1}
                  permanent
                >
                  {landmark.name}
                </Tooltip>
              </Marker>
            ))} */}
            <Marker position={routePositions[0]} icon={startIcon}></Marker>
            {/* <Marker position={routePositions[0]} icon={startIcon}>
              <Tooltip direction="left" offset={[50, 100]} opacity={1} permanent>
                <span style={{ fontWeight: 'bold', fontSize: '15px', color: 'green', marginTop: '300px' }}>
                  {locationname}
                </span>
              </Tooltip>
            </Marker> */}
            <Marker position={routePositions[routePositions.length - 1]} icon={endIcon} />
          </>
        )}
      </MapContainer>
      {/* <Legend />  */}
    </div>
    </div>
    </div>
    </div>
  );
};

export default App;
