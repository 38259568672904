import React, { useState } from "react";
import { RxSlash } from "react-icons/rx";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import CustomToastContainer from "../../CustomToastContainer";
import gimg from "../../assets/brickdemoicons/gimg.png";
import galleryImg1 from "../../assets/brickdemoicons/cadex.jpg";
import { Modal } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import autocad from '../../assets/brickdemoicons/Auto cad.png'
function History() {
  const [tableShow, setTableShow] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const [err, setErr] = useState("");
  const userData = useSelector((state) => state.userDetails);
  // const [chainage] = useState([]);
  const [filteredChainage, setFilteredChainage] = useState([]);
  const [showImg, setShowImg] = useState(false);
  const [galleryImg, setGalleryImg] = useState("");
  const [showImg1, setShowImg1] = useState(false);
  const [cadImg, setCadImg] = useState([]);
  const [historylist, setHistorylist] = useState([]);
  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });
  const [messageList, setMessageList] = useState([]);
  const[digiter,setDigiter]=useState("")
  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessageList([]);
    
    
    if (name === "from") {
      
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
       setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          } else {
            setErr("");
          }
        }

        return updatedData;
      });
    }

    if (e.target.name === "from") {
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );
      setFilteredChainage(filtered);
    }

    //type of work , http://localhost:8080/brick/api/work_details/get/type_of_work?project_id=PR-2&start_chainage=2.03&end_chainage=5.7
    if (formData.from != "" && formData.from1 != "") {
      if (
        e.target.name === "to1" ||
        formData.to1 != "" ||
        (e.target.value != "" && formData.to != "")
      ) {
        if (formData.to != "" && formData.from != "" && formData.from1 != "") {
          axios
            .get(`${baseurl}/api/work_details/get/type_of_work`, {
              params: {
                project_id: userData.projectId,
                start_chainage: `${formData.from}.${formData.from1}`,
                end_chainage: `${formData.to}.${formData.to1}`,
              },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              const type_of_work_list = data.type_of_work_list;
              setTypeOfWork(type_of_work_list);
              // ////////alert("type of work", type_of_work_list);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      }
    }

    //structure //http://localhost:8080/brick/api/work_details/get/strs_or_layers?project_id=PR-2&type_of_work=TYPE1
    if (e.target.name === "type" && e.target.value != "") {
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
          //console.log(strs_or_layers_list);
          // ////////alert(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const validate = () => {
    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      setTableShow(true);
      setErr("");
    }
  };

  const handleSubmit = (e) => {
    setMessageList([]);

    e.preventDefault();
    validate();

  
    axios({
      method: "GET",

      url:
        baseurl +
        "/api/work_details/get/str_or_layer_ids?project_id=" +
        userData.projectId +
        "&type_of_work=" +
        formData.type +
        "&strs_or_layers=" +
        formData.structure +
        "&rqd_for=history",

      headers: {
        "Content-Type": "multipart/form-data",
        //  Authorization: 'Bearer ' + userData.token
      },
    })
      .then((response) => {
        //console.log(response.data);
        const RequestData = response.data;
        const list = RequestData.str_or_layer_ids_data;
        setHistorylist(list);
        //console.log(JSON.stringify(list[0]));
      })
      .catch((error) => {
        //console.log(error);
        // setErrormsg(error.response.data.message)
      });
  };

  const [isChecked, setisChecked] = useState("");
  const [input2, setInput2] = useState("");

  // Handle change for multiple inputs
  const handleInputChange = () => {
    setisChecked(!isChecked);
  };
  const handleInput1Change = (event) => {
    setInput2(event.target.checked);
    //  ////////alert(event.target.value);
  };

  const handleImgShow = (img) => {
  
    setGalleryImg(img);
    setShowImg(true);
  };

  const handleImgShow1 = (img) => {
    
    setCadImg(img);
    setShowImg1(true);
  };

  const handleImgClose = () => {
    setShowImg(false);
    setGalleryImg("");
  };

  const handleImgClose1 = () => {
    setShowImg1(false);
    setCadImg([]);
  };

  return (
    <>
      <CustomToastContainer messageList={messageList} />

      <div
        className=" container-fluid w-100 method-cont ml-4 "
        style={{
          position: "absolute",
          top: "150%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="row sh border bg-white mx-auto  pt-3 pb-3 rounded-pill border"
          style={{ maxWidth: "85%" }}
        >
          <div className="col-12 "></div>
          <div className="col-12  ">
            <div className="container ">
              <div className="row">
                <div className="col-1">
                  <label
                    className="form-label mb-0 mt-1"
                    style={{ fontWeight: "500", fontSize: "15px" }}
                  >
                    Chainage&nbsp;
                  </label>
                </div>
                <div className=" col-lg-5  ">
                  <div className="w-100  p-0">
                    <div className="row ">
                      <div className="col-lg-6 ">
                        <div class=" input-group">
                          <label
                            className="form-label mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            From :&nbsp;
                          </label>
                          <select
                            onChange={handleChange}
                            value={formData.from}
                            name="from"
                            className="form-control border rounded-0"
                            style={{
                              // height: "30px",
                              width: "30px",
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {userData.chainageArr != undefined &&
                              userData.chainageArr.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>

                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="from1"
                            type="text"
                            value={formData.from1}
                            onChange={handleChange}
                            className="form-control border rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div class="input-group">
                          <label
                            className="form-label  mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            To :&nbsp;
                          </label>

                          <select
                            name="to"
                            onChange={handleChange}
                            value={formData.to}
                            className="border form-control rounded-0"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {formData.from1.length == 3 &&
                              filteredChainage.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0 rounded-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="to1"
                            type="text"
                            value={formData.to1}
                            onChange={handleChange}
                            className=" border form-control rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 px-0 ">
                  <div className=" mx-auto w-100 text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                      className=" border form-control rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Type of Work...</option>
                      {formData.to1.length == 3 && typeOfWork.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 ">
                  <div className="mx-auto w-100 text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="structure"
                      onChange={handleChange}
                      value={formData.structure}
                      className=" border form-control rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Structure/Layer</option>
                      {structure.map((item, index) => (
                        <option key={index} value={item==="Structures And Layers"?"All":item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-center">
                  <div className="col-1"></div>

                  <button
                    // disabled={tableShow ? true : false}
                    onClick={digiter=='' ?handleSubmit :""}
                    className="btn text-center text-white rounded-pill mt-0 "
                    style={{
                      backgroundColor: "#1953AA",
                      fontSize: "14px",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-1"></div>
              {<div className="text-red er"><center>{digiter}</center></div>}
            </div>
          </div>
        
        </div>
        {/* <div className="" style={{position:"relative"}}> */}
        {tableShow && (
          <div
            className=" pt-0 border-0  pb-3 rounded s row scrollable-div"
            style={{
              position: "absolute",
              top: "295px",
              left: "50%",
              transform: "translate(-50%,-50%)",
              width: "85%",
              height: "400px",
            }}
          >
            <div
              className="w-100  "
              style={{
                background: "linear-gradient(to right,#4889CF,#144A9A)",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                position: "sticky",
                top: "0",zIndex:'9999'
              }}
            >
              <h6 className="text-center p-2 text-white">History</h6>
            </div>
            <div className="row mx-0 border" >
              {/* <div className="col-12">
                <div className="row pt-2" style={{ fontSize: "13px" }}>
                  <span className="col-4 text-center ">
                    1/0 - 6/0 ABC STRUCTURE
                  </span>
                  <span className="col-4 text-center">
                    {" "}
                    1/0 - 6/0 ABC STRUCTURE
                  </span>
                </div>
              </div>
              <br />
              <br /> */}

              <div className="col-12 col-xl-12 col-lg-12 ">
                <div className="row  " style={{ fontSize: "13px" }}>
                  {historylist.length !== 0 &&
                    historylist.map((item, index) => {
                      return (
                        <div className="col-lg-12 col-xl-12 mb-1 " key={index}>
                          <div className="row ">
                          <div className="col-lg-4 col-xl-4 pb-2 " style={{ fontSize: "14px" }}><b>{item.structure}</b></div>
                          <div className="col-lg-8 col-xl-8"></div>
                            <div className="col-lg-4 col-xl-4">
                              <table className="table table-borderless  m-0 rounded-bottom">
                                <tbody class="text-left">
                                  <tr >
                                    <th
                                     style={{ color: "#000000" }}
                                      className="thhis11"
                                    >
                                      Testing
                                    </th>
                                    
                                    <td
                                    style={{ color: "#095091" ,textAlign:'left'}}
                                    >
                                     :&nbsp;  {item.test_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                     className="thhis11"
                                     style={{ color: "#000000" }}
                                    >
                                      Bill Quantity
                                    </th>
                                    
                                    <td style={{ color: "#095091" ,textAlign:'left'}}>
                                    :&nbsp;  {item.billed_volume_or_area_qty}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                     className="thhis11"
                                     style={{ color: "#000000" }}
                                    >
                                      Bill Amount
                                    </th>
                                    
                                    <td style={{ color: "#095091" ,textAlign:'left'}}>
                                    :&nbsp; {item.billed_amount}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <hr/>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                              <table className="table table-borderless m-0 rounded-bottom ">
                                <tbody class="text-left">
                                  <tr >
                                    <th
                                    className="thhis11"
                                   style={{ color: "black !important" }}
                                   
                                    >
                                      Current Status
                                    </th>
                                    
                                    <td
                                     
                                      style={{ color: "#095091" ,textAlign:'left'}}
                                    >
                                     :&nbsp; {item.current_status}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                     style={{ color: "#000000" }}
                                     className="thhis11"
                                    >
                                      Balance Quantity
                                    </th>
                                    
                                    <td style={{ color: "#095091" ,textAlign:'left'}}>
                                      :&nbsp;{item.balance_volume_or_area_qty}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                     className="thhis11"
                                    style={{ color: "#000000" }}
                                    >
                                      Balance Amount
                                    </th>
                                    
                                    <td  style={{ color: "#095091" ,textAlign:'left'}}>
                                      :&nbsp;{item.balance_amount}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <hr/>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                              <table className="table table-borderless m-0 rounded-bottom">
                                <tbody class="text-left">
                                  <tr >
                                    <th
                                     className="thhis11"
                                    style={{ color: "#000000" }}
                                    >
                                      No. of Bills
                                    </th>
                                    
                                    <td
                                    style={{ color: "#095091" ,textAlign:'left'}}
                                    >
                                      :&nbsp; {item.no_of_running_bills}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                       style={{ color: "#000000" }}
                                        className="thhis11"
                                    >
                                      Cad Image
                                    </th>
                                    <td style={{ color: "#095091" ,textAlign:'left'}}> 
                                      
                                       :&nbsp; {item.cad_image_url.length!=0 ?<img src={autocad} alt="img" width={25}  onClick={(e)=>handleImgShow1(item.cad_image_url)} />:"-"}
                                  
                                      </td>
                                  </tr>
                                  <tr>
                                    <th
                                     className="thhis11"
                                      style={{ color: "#000000" }}
                                    >
                                      Gallery Image
                                    </th>
                                    
                                    <td style={{ color: "#095091" ,textAlign:'left'}}>
                                    
                                       :&nbsp;{item.progress_image_url.length!=0 ? <img src={gimg} alt="img" width={25}    onClick={(e)=>handleImgShow(item.progress_image_url)}/>:'-'}
                                    
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <hr/>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* </div> */}

        {showImg && (
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            animation={true}
          >
            <Modal.Body>
              <div className=" bg-white ">
                <div className="w-100 d-inline-flex justify-content-end pr-2">
                  <button className="btn p-0 bg-white" onClick={handleImgClose}>
                    <IoMdClose />
                  </button>
                </div>
                <div className="text-center">
                          {/* {galleryImg.map((imgSrc, index) => (
                            <img
                              key={index}
                              src={imgSrc}
                              alt={`cadimg-${index}`}
                              width={768}
                              height={500}
                            />
                          ))} */}
                            <img
                            
                              src={galleryImg}
                              
                              width={768}
                              height={500}
                            />
                        </div>
              </div>
            </Modal.Body>
          </Modal>
        )}


{showImg1 && (
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            animation={true}
          >
            <Modal.Body>
              <div className=" bg-white ">
                <div className="w-100 d-inline-flex justify-content-end pr-2">
                  <button className="btn p-0 bg-white" onClick={handleImgClose1}>
                    <IoMdClose />
                  </button>
                </div>
                <div className="text-center">
                          {cadImg.map((imgSrc, index) => (
                            <img
                              key={index}
                              src={imgSrc}
                              alt={`cadimg-${index}`}
                              width={768}
                              height={500}
                            />
                          ))}
                        </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </>
  );
}

export default History;
