import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'

function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const [projectname, setProjectname] = useState('')
  const [projectnameValid, setProjectnameValid] = useState(false)
  const [projectnameerror, setProjectnameerror] = useState('')
  const [contactno, setContactno] = useState('')
  const [contactnoValid, setContactnoValid] = useState(false)
  const [contactnoerror, setContactnoerror] = useState('')
  const [clientname, setClientname] = useState('')
  const [clientnameValid, setClientnameValid] = useState(false)
  const [clientnameerror, setClientnameerror] = useState('')
  const [contactperson, setContactperson] = useState('')
  const [contactpersonValid, setContactpersonValid] = useState(false)
  const [contactpersonerror, setContactpersonerror] = useState('')

    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    }, [])

  function handleProjectname(e) {
   //console.log(e.target.value)
    setProjectname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleClientname(e) {
    setClientname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleContactperson(e) {
    setContactperson(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {
    if (fieldName === 'projectname') {
      //console.log(value)
      if (value.length != 0) {
        setProjectnameerror('')
        setProjectnameValid(true)
      } else {
        setProjectnameerror('Please enter your Project name')
        setProjectnameValid(false)
      }
    }
    
 
 
    if (fieldName === 'contactno') {
        if (value.length != 0 && value.length==10) {
          setContactnoerror('')
          setContactnoValid(true)
        } else {
          setContactnoerror('Please enter valid Contact number')
          setContactnoValid(false)
        }
      }
      if (fieldName === 'clientname') {
        //console.log(value)
    
        if (value != "") {
          setClientnameerror('')
          setClientnameValid(true)
        } else {
          setClientnameerror('Please select Client name')
          setClientnameValid(false)
        }
      }
      if (fieldName === 'contactperson') {
        //console.log(value)
        if (value != "") {
          setContactpersonerror('')
          setContactpersonValid(true)
        } else {
          setContactpersonerror('Please select Contact person')
          setContactpersonValid(false)
        }
      }
  }

  function onSubmitEvent(event) {
    event.preventDefault()
    
  //console.log(projectname)
  //console.log(contactno)


    if (projectnameValid &&  contactpersonValid && clientnameValid && contactnoValid) {
 // ////alert(clientname)
      // //console.log(name)
      // //console.log(phno)

      //   let userData = new FormData()
      //   userData.append('name', name)
      //   userData.append('email', '')
      //   userData.append('phone_no', phno)
      //   axios
      //     .post(baseurl + '/api/freeTrialMarketing', userData, {
      //       headers: { 'Content-Type': 'multipart/form-data' },
      //     })

      //     .then((response) => {
      //       setSuccess(true)
      //     })
      //     .catch((error) => {
      //       // //console.log(error)
      //       setErrormsg(error.response.data.message)
      //     })
   
    }
  }
  return (
    <>
      
        {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
        {/* {success && <ButtonLink btnPath="/FreeTrialRegistration" />} */}
        <div className="cont-margin1">
            <div class="cont-margin-latest pt-2 pb-2">
                <div className='createreqst'>
              <div className='creatreqst1'>

                <div class="row pb-5">
           
                
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={onSubmitEvent}
                  >

                    <div class="row">
    <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                      <label for="formFileLg" className="login-text">
                    Project Name
                  </label>
                      <center>
                        <InputTextField
                        
                          valueText={projectname}
                          inputName="projectname"
                          inputId="projectname"
                          classname="project-box"
                          inputType="text"
                          placeholderValue="Project name"
                          handleInputChange={handleProjectname}
                          PreventEnter={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                       
                         
                        ></InputTextField>

                        <div className="login-error">{projectnameerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                      <label for="formFileLg" className="login-text">
                    Contact
                  </label>
                      <center>
                        <InputTextField
                       handleInputChange={handleContactperson}
                       valueText={contactperson}
                       inputName="contactperson"
                       inputId="contactperson"
                       classname="project-box"
                       maxLen={20}
                       inputType="text"
                       placeholderValue="Contact"
                       defaultValue={contactperson}
                       onKeyPress={(e) => {
                         e.key === 'Enter' && e.preventDefault()
                       }}
                        ></InputTextField>

                        <div className="login-error">{contactpersonerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                    Client Name
                  </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleClientname}
                          valueText={clientname}
                          inputName="clientname"
                          inputId="clientname"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                          placeholderValue="Project type"
                        ></InputTextField>

                        <div className="login-error">{clientnameerror}</div>
                        </center>
                      </div>
                    
                     
                      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                Phone Number
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleContactno}
                          valueText={contactno}
                          inputName="contactno"
                          inputId="contactno"
                          classname="project-box"
                          maxLen={10}
                          inputType="text"
                          placeholderValue="Contact number"

                        ></InputTextField>
     </center>
                        <div className="login-error">{contactnoerror}</div>
                   
                      </div>
                     
                    
                    

                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          disabled={!(projectnameValid )}
                        >
                          <b>Submit</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                </div>
              
              </div>
            </div>
            </div>
              
              </div>
            </div>
  

    
    </>
  )
}

export default FormPage
