import React, { useEffect, useState } from "react";
import "./WorkDetails.css";
import Methodology from "./Methodology";
import arrow from "../../assets/brickdemoicons/back.png";
import methodImg from "../../assets/brickdemoicons/methodImg.png";
import tdImg from "../../assets/brickdemoicons/tdImg.png";
import boqImg from "../../assets/brickdemoicons/boqImg.png";
import galleryImg from "../../assets/brickdemoicons/galleryImg.png";
import methodImg1 from "../../assets/brickdemoicons/methodImg2.png";
import tdImg1 from "../../assets/brickdemoicons/tdImg2.png";
import boqImg1 from "../../assets/brickdemoicons/boqImg2.png";
import galleryImg1 from "../../assets/brickdemoicons/galleryImg2.png";
import TestingDependency from "./TestingDependency";
import Boq from "./Boq";
import Gallery from "./Gallery";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
function WorkDetails() {
  const [method, setMethod] = useState(true);
  const [td, setTd] = useState(false);
  const [boq, setBoq] = useState(false);
  const [gallery, setGallery] = useState(false);
  const userData = useSelector((state) => state.userDetails);

  const handleMethodClick = () => {
    setMethod(true);
    setTd(false);
    setBoq(false);
    setGallery(false);
  };

  const handleTdClick = () => {
    setMethod(false);
    setTd(true);
    setBoq(false);
    setGallery(false);
  };

  const handleBoqClick = () => {
    setMethod(false);
    setTd(false);
    setBoq(true);
    setGallery(false);
  };

  const handleGalleryClick = () => {
    setMethod(false);
    setTd(false);
    setBoq(false);
    setGallery(true);
  };

  return (
    <>
      <div
        className="container-fluid wd-cont w-75 ml-4  mt-4 bg-white  pb-1"
        style={{
          position: "absolute",
          top: "130px",
          left: "55%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div className=" w-100  pt-2 d-flex justify-content-center
         align-items-center mb-4 pb-1 "s>
          <div className=" mx-auto  text-center rounded w-25  " style={{backgroundColor:'', color:'#1953AA'}}>
            <h5 className="   mb-0 ">{userData.projectName}</h5>
            <h6
      className="text-center mt-2"
      style={{  fontWeight: "700" }} 
    >
      TOTAL CHAINAGE: 1/0 - 6/0
    </h6>
          </div>
        
        </div>
        <div
          className="d-flex justify-content-center mx-auto  p-0 rounded "
          style={{
            backgroundColor: "#1C434D",
            height: "50px",
            position: "relative",
            width: "550px",
          }}
        >
          <div className=" text-center ">
            <div
              className="mr-4"
              onClick={handleMethodClick}
              style={{ cursor: "pointer" }}
            >
              <div className={`${method ? "tabMenu mx-auto pt-2" : " "}`}>
                <img src={method? methodImg1 : methodImg} width={20} alt="icon" />
              </div>
              <label
                style={{ fontSize: "12px",color: method?'#ffb700':'white' }}
                className={`${
                  method
                    ? "form-label mb-0 mt-4 pt-1 "
                    : " pt-0"
                }`}
              >
                Methodology
              </label>
            </div>
          </div>
          <div className="text-center">
            <div
              className="ml-4"
              onClick={handleTdClick}
              style={{ cursor: "pointer" }}
            >
              <div className={`${td ? "tabMenu1 pt-2" : ""}`}>
              <img src={td? tdImg1 : tdImg} width={20} alt="icon" />
              </div>

              <label
                style={{ fontSize: "12px",color: td?'#ffb700':'white' }}
                className={`${
                  td
                    ? "form-label mb-0  mt-4 pt-1"
                    : " pt-0"
                }`}
              >
                {" "}
                Testing Dependency
              </label>
            </div>
          </div>
          <div className=" ml-5 text-center">
            <div onClick={handleBoqClick} style={{ cursor: "pointer" }}>
              <div className={`${boq ? "tabMenu2 pt-2" : ""}`}>
              <img src={boq? boqImg1 : boqImg} width={20} alt="icon" />
              </div>

              <label
                style={{ fontSize: "12px",color: boq?'#ffb700':'white' }}
                className={`${
                  boq
                    ? "form-label mb-0 mt-4 pt-1"
                    : " pt-0"
                }`}
              >
                Bill Of Quantity
              </label>
            </div>
          </div>
          <div className=" ml-5 text-center">
            <div onClick={handleGalleryClick} style={{ cursor: "pointer" }}>
              <div className={`${gallery ? "tabMenu3 pt-2" : ""}`}>
                <img src={gallery? galleryImg1:galleryImg} width={20} alt="icon" />
              </div>

              <label
                style={{ fontSize: "12px",color: gallery?'#ffb700':'white' }}
                className={`${
                  gallery
                    ? "form-label mb-0 mt-4 pt-1"
                    : "pt-0"
                }`}
              >
                Gallery
              </label>
            </div>
          </div>
        </div>
      </div>

      {method && <Methodology />}
      {td && <TestingDependency />}
      {boq && <Boq />}
      {gallery && <Gallery />}
    </>
  );
}

export default WorkDetails;
