import React, { useState } from "react";
import { RxSlash } from "react-icons/rx";
import gimg from "../../assets/brickdemoicons/gimg.png";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
// import img from "../../assets/brickdemoicons/cadex.jpg";
import { connect, useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { Modal } from "react-bootstrap";
import CustomToastContainer from "../../CustomToastContainer";

function Gallery() {
  const [tableShow, setTableShow] = useState(false);
  const [err, setErr] = useState("");
  const [showImg, setShowImg] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const userData = useSelector((state) => state.userDetails);
  const [searchData, setSearchData] = useState([]);
  const [galleryImg, setGalleryImg] = useState("");
  const [filteredChainage, setFilteredChainage] = useState([]);
  const[digiter,setDigiter]=useState("")
  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });
  const [messageList, setMessageList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessageList([]);
    
    
    if (name === "from") {
      
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
       setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));
      setTypeOfWork([]);
    setStructure([])
    } 
    else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }

      
      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          } else {
            setErr("");
          }
        }
        return updatedData;
      });
    }

    if (e.target.name === "from") {
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );
      setFilteredChainage(filtered);
    }

    //type of work , http://localhost:8080/brick/api/work_details/get/type_of_work?project_id=PR-2&start_chainage=2.03&end_chainage=5.7
    if (formData.from != "" && formData.from1 != "") {
      if (
        e.target.name === "to1" ||
        formData.to1 != "" ||
        (e.target.value != "" && formData.to != "")
      ) {
        if (formData.to != "" && formData.from != "" && formData.from1 != "") {
          axios
            .get(`${baseurl}/api/work_details/get/type_of_work`, {
              params: {
                project_id: userData.projectId,
                start_chainage: `${formData.from}.${formData.from1}`,
                end_chainage: `${formData.to}.${formData.to1}`,
              },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              const type_of_work_list = data.type_of_work_list;
              setTypeOfWork(type_of_work_list);
              // //////alert("type of work", type_of_work_list);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      }
    }

    //structure //http://localhost:8080/brick/api/work_details/get/strs_or_layers?project_id=PR-2&type_of_work=TYPE1
    if (e.target.name === "type" && e.target.value != "") {
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
          ////console.log(strs_or_layers_list);
          // //////alert(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    setTableShow(false)

  };

  const validate = () => {
    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      setErr("Please fill all the fields");
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      axios
        .get(
          `${baseurl}/api/work_details/get/str_or_layer_ids?rqd_for=gallery&`,
          {
            params: {
              project_id: userData.projectId,
              strs_or_layers: formData.structure,
              type_of_work: formData.type,
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userData.token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data.str_or_layer_ids_data;
          ////console.log(data);
          setSearchData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      setTableShow(true);
      setErr("");
    }
  };

  const handleSubmit = (e) => {
    setMessageList([]);
    e.preventDefault();
    validate();
  };

  const handleImgClose = () => {
    setShowImg(false);
    setGalleryImg("");
  };

  const handleImgShow = (img) => {
    setGalleryImg(img);
    setShowImg(true);
  };

  return (
    <>
      <CustomToastContainer messageList={messageList} />

      <div
        className=" container w-75   ml-4"
        style={{
          position: "absolute",
          top: "40%",
          left: "55%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="row sh border bg-white mx-auto  pt-3 pb-3 rounded-pill border" 
          style={{ maxWidth: "85%" }}
        >
          <div className="col-12 ">
           
          </div>
          <div className="col-12  ">
            <div className="container ">
              <div className="row">
                <div className="col-1"><label
                            className="form-label mb-0 mt-1"
                            style={{fontWeight:'500', fontSize: "15px" }}
                          >
                           Chainage&nbsp;
                          </label></div>
                <div className=" col-lg-5  ">
                  <div className="w-100  p-0">
                    
                   
                    <div className="row ">
                      <div className="col-lg-6 ">
                        <div class=" input-group">
                          <label
                            className="form-label mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            From :&nbsp;
                          </label>
                          <select
                            onChange={handleChange}
                            value={formData.from}
                            name="from"
                            className="form-control border rounded-0"
                            style={{
                              // height: "30px", 
                              width:'30px',
                              fontSize: "12px",
                            }}
                          >
                            <option disabled value=""></option>
                            {userData.chainageArr != undefined &&
                              userData.chainageArr.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>

                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="from1"
                            type="text"
                            value={formData.from1}
                            onChange={handleChange}
                            className="form-control border rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div class="input-group">
                          <label
                            className="form-label  mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            To :&nbsp;
                          </label>

                          <select
                            name="to"
                            onChange={handleChange}
                            value={formData.to}
                            className="border form-control rounded-0"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {formData.from1.length == 3 && 
                              filteredChainage.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0 rounded-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="to1"
                            type="text"
                            value={formData.to1}
                            onChange={handleChange}
                            className=" border form-control rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 px-0 ">
                  <div className=" mx-auto w-100 text-center">
                  {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}
                   
                    <select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                      className=" border form-control rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Type of Work...</option>
                      {formData.to1.length == 3 && typeOfWork.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 ">
                  <div className="mx-auto w-100 text-center">
                  {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}
                   
                    <select
                      name="structure"
                      onChange={handleChange}
                      value={formData.structure}
                      className=" border form-control rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="" disabled>Structure/Layer</option>
                      {structure.map((item, index) => (
                        <option key={index} value={item==="Structures And Layers"?"All":item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-center">
                <div className="col-1"></div>

               
                <button
                  disabled={tableShow ? true : false}
                  onClick={digiter=='' ?handleSubmit :""}
                  className="btn text-center text-white rounded-pill mt-0 "
                  style={{
                    backgroundColor: "#1953AA",
                    fontSize: "14px",
                  }}
                > 
                  Search
                </button>
                
              </div>
              </div>
                <div className="col-1"></div>
             
              
            </div>
          </div>
        </div>
        {<div className="text-red er"><center>{digiter}</center></div>}
      </div>
      {tableShow && (
        <div
          className="w-75 "
          style={{
            position: "absolute",
            top: "73%",
            left: "55%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <div className="scrollable-div border-0 pt-0"style={{height:'350px'}}>
          
          <table className=" table table-bordered table-md-responsive mx-auto mt-0">
            <thead
              style={{
                fontSize: "12px",
                background: "linear-gradient(to right,#4889CF,#144A9A)",
                position:"sticky",top:"0"
              }}
            >
              <tr style={{}}>
                <th>Item Of Work</th>
                {(formData.structure === "Structures" ||
                    formData.structure === "All" || formData.structure === "Structures And Layers") && <th>Structure ID</th>}
                {(formData.structure === "Layers" ||
                    formData.structure === "All" || formData.structure === "Structures And Layers") && <th>Layer ID</th>}
                <th>Image</th>
              </tr>
            </thead>
            <tbody className="border">
              {searchData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-dark p-1" style={{fontSize:'12px'}}>{item.item_of_work}</td>
                    {(formData.structure === "Structures" ||
                        formData.structure === "All" || formData.structure === "Structures And Layers") && (
                      <td className="text-dark p-1" style={{fontSize:'12px'}}>{item.str_id}</td>
                    )}
                    {(formData.structure === "Layers" ||
                        formData.structure === "All" || formData.structure === "Structures And Layers") && (
                      <td className="text-dark p-1" style={{fontSize:'12px'}}>{item.layer_id}</td>
                    )}

                    <td className="p-1">
                      {item.progress_image_url.length==0 ? (
                        "-"
                      ) : (
                        <button
                          className="btn p-0"
                          onClick={() => handleImgShow(item.progress_image_url[0])}
                        >
                           <img src={gimg} alt="img" width={25} />
                          </button>
                      
                      )}  </td>
                      {/* {(formData.structure === "Layers" ||
                        formData.structure === "All") && (
                        <td
                          className="text-dark p-1"
                          style={{ fontSize: "12px" }}
                        >
                          {item.layer_id}
                        </td>
                      )} */}

                      {/* <td className="p-1">
                        {item.progress_image_url === "-" ? (
                          "-"
                        ) : (
                          <button
                            className="btn p-0"
                            onClick={() =>
                              handleImgShow(item.progress_image_url)
                            }
                          >
                            <img src={gimg} alt="img" width={25} />
                          </button>
                        )}
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {showImg && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          animation={true}
        >
          <Modal.Body>
            <div className=" bg-white ">
              <div className="w-100 d-inline-flex justify-content-end pr-2">
                <button className="btn p-0 bg-white" onClick={handleImgClose}>
                  <IoMdClose />
                </button>
              </div>
              <div className="text-center">
                <img src={galleryImg} alt="img" width={768} height={500} />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default Gallery;
